.data-logger {
  padding: 10px 10px 25px 15px;
  position: relative;
  &:nth-child(even) {
    background-color: #eaf4fb;
  }

  .data-logger-action {
    width: 20px;
    position: relative;
    align-items: center;

    .button-icon {
      background:none!important;
      color: #110AFF;
      border:none;
      margin-top: 3px;
      padding:2px;
      font: inherit;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      span {
        justify-content: center;
        margin:1px;
        z-index: 99;
        background: #2980B9;
        border-radius: 50%;
        height: 5px;
        width: 5px;
        color: #2980B9;

      }
    }

    .ant-dropdown {
      top: 0 !important;
      left: 0 !important;
      width: 150px;
      z-index: 98;

      ul {
        box-shadow: none;
        border: 1px solid #2980B9;
        border-radius: 6px;
        padding-top: 0;
        padding-bottom: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        li {
          border-bottom: 1px solid #2980B9;
          padding: 0;
          display: block;
          height: 30px;
          position: relative;
          overflow: hidden;

          a{
            padding-left: 20px;
            text-align: left;
            display: block;
            top: 0;
          }

          &:last-child {
            border: 0;
          }

          button {
            background-color: transparent;
            width: 100%;
            height: 100%;
            padding-left: 20px;
            text-align: left;
          }

        }
      }

    }

    a {
      display: block;
      text-align: center;
      position: relative;
      width: 100%;
      margin: 0;
      height: 15px;
      top: 5px;
      z-index: 99;
      span {
        background-color: #2980B9;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        margin-bottom: 3px;
        display: block;
        margin-left: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .data-logger-content-wrap {
    width: 100%;

    .data-logger-content-first-row {
      margin-bottom: 10px;
    }

  }

  .data-logger-row-wrap {

    display: grid;
    grid-template-columns: 2fr 2fr 2fr 1fr;
    grid-gap: 15px;
    width: 100%;


    .data-logger-row-item {
      line-height: 16px;

      .no-data-label {
        color: #7F8C8D;
        font-style: italic;
      }


      span {
        font-size: 10px;
        color: #7F8C8D;
      }

      p {
        color: #000;
        font-size: 14px;
      }
    }
  }
}