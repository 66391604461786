.channel-preview-with-icon{
  display: inline-flex;
  align-items: center;
}

.span-channel-preview{
  cursor: pointer;
  background: none;
  border: 0;
  color: #110AFF;
  i{
    color: #000000;
  }
}