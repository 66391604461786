button.clipboard {
  display: block;
  width: 100%;
  background: transparent;
  border: transparent;
  text-align: left;
  padding: 0;
}

.modal-menu {
  width: 25%;
}

.tabs {
  display: flex;
  flex-direction: row !important;
}

.tabs-left-content {
  border-left: none;
}
.sketch-picker {
  width: 235px !important;
}
.edit-data-struc{
  color: #333;
  -webkit-transition-property: none;
  -moz-transition-property: none;
  -o-transition-property: none;
  transition-property: none;
}

.edit-data-struc:hover{
  color: white;
}
div:hover > .edit-data-struc{
  color: white;
}
