/* .datasource-name {
  width: fit-content;
} */

.input-value {
  overflow-x: scroll;
}

.input-space {
  margin-top: 20px !important;
}
