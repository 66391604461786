.login-container {
  display: flex;
  flex: 1;
  background-color: #29363f;
  justify-content: space-around;
  align-items: center;
  padding: 0 5%;
}

.login-synapsees-logo {
  width: 30%;
}

.login-divider {
  height: 45%;
  width: 2px;
  background-color: #424e56;
}

.login-form-wrapper {
  flex-direction: column;
  justify-content: space-around;
  width: 30%;
  height: 45%;
  padding: 0px 3%;
}

.login-inputs-wrapper {
  flex-direction: column;
  width: 100%;
}

.login-input {
  height: 50px;
  border-radius: 3px;
  background-color: #29363f;
  border: 1px solid #424e56;
  color: white;
  font-size: 18px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 20px;
}

.login-button {
  height: 50px;
  border-radius: 3px;
  /* background-color: #4a8d70; */
  border: 0px;
  display: block;
  padding: 7px 13px;
  text-align: center;
  font-size: 24px;
  color: #ffffff;
  white-space: nowrap;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.forgot-password-text {
  -webkit-align-self:flex-end;
  align-self: flex-end;
  text-align: right;
  font-size: 14px;
  color: #ffffff;
  font-weight: 100;
  float: right;
}
.remember-me{
  -webkit-align-self:center;
  align-self: center;
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  font-weight: 100;
  float: left;
}
.margin-left-5 {
  margin-left: 5px;
}
