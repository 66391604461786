.item {
  height: 125px;
  border-radius: 6px;
  padding: 5px;
  color: #fff;
  transition: opacity .3ms linear;
  cursor: pointer;

  &.viewer-structure {
    background-color: #3498DB;

    &.selected {
      background-color: #004B7C;
    }

    .viewer-drag-handle {
      position: absolute;
      right: 5px;
    }

    &:hover {
      opacity: .9;
    }

  }

  &.item-new-viewer {
    background-color: #16A085;


    &:hover {
      opacity: .9;
    }

    .item-wrap {
      height: 100%;
      .item-title {
        font-size: 12px;
        display: flex;

        i {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }

  }

  &.item-start-create-tree {
    background-color: #dddd;

    i {
      color: #E67E22;
      font-size: 40px;
    }

  }

  .item-wrap {
    height: auto;
    position: relative;
    text-align: center;

    .item-viewer-title {
      font-size: 15px;
      overflow: hidden;
      -moz-box-orient: vertical;      /* Mozilla */
      /* autoprefixer: off /
      -webkit-box-orient: vertical;
      / autoprefixer: on */
      -webkit-line-clamp: 3;
      -moz-line-clamp: 3;
      height: 60px;
      line-height: 18px;
      p{
        margin-left: 7px;
        font-size: 12px;
      }

    }

    .item-description {
      font-size: 11px;
      padding: 0 10px;
      height: 60px;
    }

    &:hover {
      .item-action {
        opacity: 1;
      }
    }

    .item-action {
      opacity: 0;
      position: absolute;
      top: -4px;
      right: 0;
      z-index: 2;

      &.js-loader {
        opacity: 1;
      }

    }
    .item-options{

      position: absolute;
      left: 5px;
      top: 0;
      height: 28px;
      z-index: 999;
      width: 10px;

      button {
        display: block;
        text-align: center;
        position: relative;
        width: 20px;
        margin: 0;
        height: 23px;
        top: 3px;
        right: 7px;
        z-index: 99;
        background-color: transparent;
        border: 0;
        cursor: pointer;

        span {
          background-color: #ffffff;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          margin-bottom: 2px;
          display: block;
          margin-left: 5px;

          &:last-child {
            margin-bottom: 0;
          }
        }
        &.ant-dropdown-open{
          span{
            background-color: #2980B9;
          }
        }
      }
      .ant-dropdown {
        top: 0 !important;
        left: 0 !important;
        width: 150px;
        z-index: 98;

        ul {
          box-shadow: none;
          border: 1px solid #2980B9;
          border-radius: 6px;
          padding-top: 0;
          padding-bottom: 0;
          overflow: hidden;
          display: flex;
          flex-direction: column;


          li {
            border-bottom: 1px solid #2980B9;
            display: block;
            height: 28px;
            position: relative;
            overflow: hidden;
            padding-left: 15px;


            a{
              padding-left: 20px;
              text-align: left;
              display: block;
              top: 0;
            }

            &:last-child {
              border: 0;
            }

            button {
              background-color: transparent;
              width: 100%;
              height: 100%;
              padding-left: 20px;
              text-align: left;
            }

          }
        }

      }
    }
  }

}

