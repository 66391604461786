.dashboard-view-header {
  grid-template-columns: 1fr 2fr 3fr 3fr;
  grid-template-rows: auto;
  grid-gap: 5px;
  box-decoration-break: clone;
  align-items: center;

  .dashboard-show-probes-title {
    font-size: 11px;
    color: #000;
  }
  .dashboard-view-header-sidebar-button{
    button{
      background: transparent;
      &[ant-click-animating-without-extra-node=true]:after {
        border: 0;
      }
      &:active{
        color: #272727;
      }
      &:focus{
        color: #272727;
      }
      &:hover{
        color: #272727;
      }
    }
  }
  .dashboard-view-info {
    z-index: 99;
    position: relative;

    #dashboard-info {
      padding: 0;
      background-color: transparent;
      height: auto;
      z-index: 99;

      &[ant-click-animating-without-extra-node=true]:after {
        border: 0;
      }

    }

    .dashboard-name-dashboard {
      font-size: 20px;
      font-weight: 500;
      color: #000;
      margin-left: 10px;
      z-index: 99;
      padding-right: 40px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 175px;

    }
    .dashboard-view-info-show{
        opacity: 1 !important;
        visibility: visible !important;
    }

    .dashboard-view-info-description {
      opacity: 0;
      visibility: hidden;
      background: #fff;
      position: absolute;
      top: -10px;
      padding: 15px;
      left: -15px;
      border-radius: 6px;
      width: 100%;
      min-width: 250px;


      .dashboard-view-info-description-content {
        padding-top: 30px;
        padding-bottom: 10px;
        font-size: 11px;
        color: #272727;
      }

      .dashboard-view-info-close-button {
        position: absolute;
        right: 15px;
        top: 13px;
        height: 20px;
        padding: 0;
        line-height: 0;
        box-shadow: none;
        z-index: 99;


        i {
          font-size: 11px;
          color: #9f9f9f;
          font-weight: 600;
        }

      }

    }
  }

  .dashboard-view-date-picker{
    margin-bottom: 15px;
    .dashboard-view-slider {

      button{
        background-color: transparent;
        padding: 5px;

        &[ant-click-animating-without-extra-node=true]:after {
          border: 0;
        }
      }

      .anticon-calendar {
        display: none;
      }

      .anticon-close-circle {
        display: none;
      }

      input {
        padding: 0;
        width: auto;
        font-size: 11px;
        border: 0;
        background: transparent;
        height: auto;
        margin-right: 5px;
        text-align: center;
        color: #000;

        &[disabled] {
          color: #888;
        }

      }

      .datepicker-slider-end {
        min-width: 140px!important;
        input {
          margin-left: 5px;
        }
      }
      .datepicker-slider-start {
        text-align: right;
        min-width: 140px!important;

      }

      .ant-slider {
        width: 100%;

        .ant-slider-rail{
          background-color: #DFDFDF;
          border: 2px solid #DFDFDF;
        }

        .ant-slider-track {
          background: linear-gradient(90deg, #159957 0%, #155799 98.62%);
        }

        .ant-slider-handle {
          background-color: #007521;
          border: none;

          &:focus {
            box-shadow: none;
          }

        }
        &.ant-slider-disabled{
          .ant-slider-track {
            background: #888;
            -webkit-transition: all 1ms;
            -o-transition: all 1ms;
            transition: all 1ms;
          }
          .ant-slider-handle {
            background-color: #888;
            -webkit-transition: all 5ms;
            -o-transition: all 5ms;
            transition: all 5ms;
          }
        }
      }
    }

    .dashboard-view-predefine-date{
      ul{
        li {
          margin-right: 5px;

          button{
            background-color: #fff;
            border: 1px solid #cacccc;
            font-size: 10px;
            padding: 1px 3px;
            border-radius: 3px;
            font-weight: 400;
            letter-spacing: .3px;
            color: #333;
            height: auto;
          }

          &:last-child{
              margin-right: 0;
          }
        }
      }
    }
  }

  .dashboard-view-cta{
    justify-content: flex-end;

    button {
      background-color: transparent;
      padding: 0 5px;
    }

    .dashboard-view-cta-save{
      i{
        margin-top: 3px;
      }
    }

    .dashboard-view-cta-select-layout{
      z-index: 98;

      &.js-modal--select-layout{
        z-index: 99;
        button{
          background-color: #fff;
          border-radius: 5px 5px 0 0;
        }

        .modal-for-select-layout {
          visibility: visible;
          opacity: 1;


          &.js-open-modal-for-select-layout {
            visibility: visible;
            opacity: 1;
          }

          .dashboard-view-template {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: auto;
            grid-gap: 12px;
            -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
            margin-top: 30px;
          }
          .modal-for-select-layout-footer{
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;

            button {
              border-radius: 5px;
            }
            button:hover{
              background-color: #16a085;
              color: #ffffff;
            }
            .cancel-button-footer-layout{
              background-color: #ffffff;
              border-color:#ffffff;
              &:hover{
                background-color: #ffffff;
                color: #ff0000;
              }
            }
          }

        }
      }

      .modal-for-select-layout {
        position: absolute;
        background-color: #fff;
        right: 132px;
        visibility: hidden;
        padding: 15px;
        opacity: 0;
        -webkit-transition: visibility 0s, opacity 0.3s linear;
        -o-transition: visibility 0s, opacity 0.3s linear;
        transition: visibility 0s, opacity 0.3s linear;
        border-radius: 6px 0 6px 6px;
        width: 460px;
        z-index: 99;

        h5 {
          color:#272727;
          font-size: 16px;
          font-weight: 600;
        }

        p{
          color:#272727;
          font-size: 11px;
        }
      }
    }

  }
}
