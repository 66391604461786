.sidebar-syn-search-tree {
  width: 100%;
  height: 100%;
  padding: 0 !important;
}

.sidebar-syn-text-wrap {
  position: relative;
}

.sidebar-syn-search-body {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding-bottom: 100px;
  width: 100%;
}

.sidebar-syn-search-body .sidebar-syn-extra {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: bold;
}

.sidebar-syn-text-wrap span {
  display: block;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  position: relative;
  top: 35px;
}

.ant-tree.ant-tree-directory
  .ant-tree-child-tree
  > li.ant-tree-treenode-selected
  > span.ant-tree-switcher {
  color: #333333 !important;
}
.syna-tree-body {
  width: 100%;
  overflow-y: auto;
  padding: 15px;
  -moz-overflow-scrolling: auto;
  min-height: 0;
}
.syna-tree-body ul {
  padding-right: 20px;
}


.hide-sidebar .sidebar-syn .sidebar-syn-search-body {
  opacity: 0;
  transition: opacity 0.25s ease-out 0s;
}

// the new code
.sidebar-tree-view-body {
  height: 100%;
}