.right-sidebar-wrap{

  border-radius: 5px;
  background-color: white;
  height: 100%;
  position: relative;
  h2{
    font-size: 18px;
    font-weight: 500;
  }
  .spanInProbestypeTitle{
    color: rgba(76, 76, 76, 0.5);
    margin-bottom: -15px;
  }
  .right-sidebar-filters-body {
    width: 100%;
    background-color: #F7F9FA;
    margin-bottom: 10px;
  }
  .disabledbutton {
    pointer-events: none;
    opacity: 0.5;
    .input-filter{
      border-top: none;

    }
  }
  .right-sidebar-title-header{
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .right-sidebar-save-and-x-button{
    height: 30px;
    justify-content: flex-end;
    padding: 5px;
    align-items: center;
    i:first-child{
      margin: 0 10px;
    }
  }
  .right-sidebar-title{
    display: block;
    .right-sidebar-title-header{
      margin-top: 0;
    }
  }
  .right-sidebar-x-axes{
    display: block;
    .right-sidebar-title-header{
      margin-top: 0;
    }
  }
  .right-sidebar-y-axes{
    display: block;
    .right-sidebar-title-header{
    }
    .right-sidebar-div-with-add-probe{
      margin-bottom: 20px;

      .select-div{
        margin-right: 45px;
      }
    }
  }
  .right-sidebar-probes-styles{
    display: block;
    .right-sidebar-title-header{

    }

    .select-div{
      justify-content: flex-end;
      margin-right: 45px;
      margin-bottom: 20px;
    }
  }
  .select-label{
    margin-right: 5px;
    color: #7F8C8D;
  }
}
@media only screen and (max-width: 900px) {
  .right-sidebar-wrap{
      min-width: 250px !important;
  }
}
.disabledbutton {
  pointer-events: none;
  opacity: 0.5;
  .input-filter{
    border-top: none;
  }
}