.sidebar-right-filter-header {
  width: 100%;
  flex-direction: row;
  height: 50px;
  margin-top: 10px;

  .sidebar-analyses-filter-icon-wrapper {
    width: 35px;
    margin-left: 5px;
    justify-content: center;
    align-items: center;
  }

  .sidebar-right-analyses-tab-wrapper {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    width: 100%;

    .sidebar-right-header-title {
      font-size: 16px;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
      border: none;
    }
      .dropdown-wrapper-new-filter-type {
        border: 1px solid #eee;

        .dropdown-button-new-filter-type {
          width: 100% !important;
          border: 0 !important;
          border-radius: 0 !important;
        }

      }
  }
  .syna-add-filter-icon-button {
    border: none !important;
  }
}

