.view-template {

  &.selected {
    svg{
      rect{
        fill-opacity: 0.75
      }
      path{
        fill-opacity: 0.75
      }
    }
  }
}