$white_color:#FFFFFF;
$gray_black_color:#2E3032;
$gray_color:#7F8C8D;

.modal-form-mqtt{
  width: 500px;
  padding: 20px;
  margin: 0 auto;

  .row-form{
    margin-bottom: 15px;
    .username-column{
      width: 75%;
      .modal-form-label{
        color: #7F8C8D;
        font-size: 10px;
      }
      .username-icon{
        margin-right: 5px;
      }
    }
    .password-column{
      width: 30%;
      align-self: flex-end;
      .modal-form-label{
        color: #7F8C8D;
        font-size: 10px;
        display: flex;
        align-items: center;
        i{
          margin-left: 5px;
        }
      }
      .mqtt-password{
        .mqtt-password-icon{
          margin-right: 5px;
        }
      }
    }
    .modal-form-column{
      width: 100%;

      &:first-child{
        width: 260px;
        margin-right: 15px;
      }

      .modal-form-label {
        color: #7F8C8D;
        font-size: 10px;
      }

      .password-input{
        border: 0;
        padding-left: 10px;

        &:focus{
          outline: 0;
        }
      }
      .channel-input{
        padding-left: 10px;
        &:focus{
          outline: 0;
        }
      }

      .modal-mqtt-double-input {
        width: 50%;
        .ant-select-arrow-icon svg{
          transition: none !important;
        }
        input{
          width: 100%;
        }

        &:first-child{
          margin-right: 15px;
        }

      }
      .modal-form-column-ip-port{

        width: 50%;
        .modal-form-column-ip-port-row{
          width: 100%;
          .copy-icon{
            margin-right: 5px;
          }
        }
      }
      .modal-form-label{
        display: block;
      }
      .modal-form-input{
        width: 75%;
        border-radius: 6px;
        .ant-input-number-handler-wrap{
            -webkit-transition: none;
            -moz-transition: none;
            -ms-transition: none;
            -o-transition: none;
            transition: none ;
          }
      }
      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type=number] {
        -moz-appearance: textfield;
      }
      .modal-form-column-pa-lo-ssl{
        width: 100%;
        height: 100%;
        .modal-form-column-pa-lo-ssl-row{
          width: 33%;
          height: 100%;
          display: inline-block;
          .ant-switch:after {
            transition: none;
            animation: none;
          }
          .ant-click-animating-node{
            transition: none;
            animation: none;
          }

        }
      }
      .modal-form-textarea{
        font-family: "Courier";
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
        height: 8em;
        font-size: 12px;
        background-color: $gray_black_color;
        color: white;
        padding: 15px;
      }
      .modal-form-mqtt-test{
        display: inline-flex;
        width: 100%;
        justify-content: space-between;
        button{
          background-color: $white_color;
          border: 2px solid $gray_color;
          box-sizing: border-box;
          border-radius: 6px;
          color: $gray_color;
        }
        .last-message{
          display: inline-flex;
        }
      }
    }

    &.row-form-text {

      .modal-form-column{
        width: 100%;
        margin-right: 0;
      }
    }
  }
}
.create-modal-button-mqtt{
  height: 62px;
  padding: 15px;
  text-align: right;
  button{
    height: auto;
    font-size: 14px;
    line-height: 16px;
  }
}