.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-bottom: 10px;
    font-size: 12px;
    color: #7F8C8D;
  }
  .card-input-element {
    display: none;
  }

  .card-input {
    border: 1px solid #7F8C8D;
    border-radius: 5px;
    padding: 10px;
    width:  60px;
    height: 60px;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;

    i{
      width: 100%;
      height: 100%;
    }
  }

  .card-input:hover {
    cursor: pointer;
  }

  .card-input-element:checked + .card-input {
    box-shadow: 0 0 1px 2px #0097E6;;
    border: none;

  }
}

