.filter-percentile-parameters-wrapper {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 5px;
  margin: 5px 0;

  .filter-bounds-wrapper {
    margin: 5px 0;
    flex-direction:column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .filter-bound-label {
    font-size: 12px;
  }

  .filter-input-bound {
    height: 35px !important;
    width: 100%;
    margin: 10px 0;
    padding: 2px 0;
  }
}
