.global-settings-header-container {
  position: relative;
  padding: 15px;
  height: 110px;
  width: 100%;

  .global-settings-header-title {
    h4 {
      font-size: 20px;
      text-transform: uppercase;
    }
    p{
      font-size: 12px;
      color: #4c4c4c;
    }
  }
  .global-settings-header-action {

    .btn-global-settings{

      &:hover{
        color: #212529;
        opacity: 0.6;
      }

      &:focus{
        text-decoration: none;
      }
    }

    p {
      margin-right: 15px;
      font-size: 18px;

    }
  }
}