
.input-filter {
  border-bottom: 1px solid #7F8C8D;

  &:last-child {
    border-bottom: 0;
  }


  &.input-select-filter {
    width: 100%;
    padding: 0 11px;
    position: relative;
    border-collapse: collapse;

    .ant-select-selection__clear {
      right: -9px;
      background-color: transparent;
    }


      span{
        color: rgba(76, 76, 76, .5);
        font-size: 12px;
        position: absolute;
        right: 19px;
    }


    .form-input-select {
      width: 100% !important;
      z-index: 9;

      .ant-select-selection {
        background-color: transparent;
        border: none;
        padding-right: 108px;
        box-shadow: none;

        &.ant-select-selection--multiple {
          .ant-select-selection__choice__remove {
            top: 1px;
            right: 5px;
          }
        }

      &:focus {
        box-shadow: none;
      }
        .ant-select-selection__rendered {
          margin: 0;
        }

      }
    }
  }


  .form-input-text{

    input{
      background-color: transparent;
      border: none;
      padding-right: 120px !important;
      z-index: 9;

      &:focus{
        box-shadow: none;
      }

    }
    span{
      color: rgba(76, 76, 76, .5);
      font-size: 12px;
    }
    .ant-input-suffix{
      right: 17px;
    }
  }

  &.input-date-filter {
    .date-picker-filter-wrap{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: relative;
      margin: 2px 11px;
      outline: 0;
      background: transparent;
      .span-range-picker{
        color: rgba(76, 76, 76, .5);
        font-size: 12px;
        position: absolute;
        right: 6px;
        z-index: -1;

      }

      i{
        color: rgba(76, 76, 76, .5);
        background: transparent;
        position: absolute;
        margin-right: -23px;
        display: flex;
        align-items: center;
        font-size: 12px;
        &.ant-calendar-picker-icon{
          display: none;
        }
      }
      .ant-calendar-picker{
        width: 100%;
        border: 0;
        outline: 0;
        margin: 0;
        box-shadow: none;
        .ant-calendar-range-picker-separator{
          display: unset;
          align-self: center;
          vertical-align: middle;
          line-height: 31px;
        }
        input{
          display: inline;
        }
        span{
          border: 0;
          padding: 0;
          outline: 0;
          background: transparent;
        }
        .ant-calendar-picker-input{
          display: flex;
          align-items: center;
          box-shadow: none;

        }
      }
    }

    .no-margin {
      margin: 0;
    }

    .date-filter-wrap {
      padding: 0 11px;
      position: relative;

      .ant-calendar-picker {
        position: relative;
        padding-left: 11px;

        input {
          padding-left: 20px;
          background-color: transparent;
          border: none;
          //padding-right: 89px !important;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.65);

          .ant-calendar-picker-clear{
            background-color: transparent;
          }

        &:focus {
            box-shadow: none;
          }
        }
        .ant-calendar-picker-icon {
          position: absolute;
          left: 11px;
        }
      }

      span {
        color: rgba(76, 76, 76, .5);
        font-size: 12px;
        position: absolute;
        right: 11px;
      }
    }
  }
  .popupParagraf{
    color: rgba(76, 76, 76, .5);
    align-items: center;
    height: 30px;
    width: 100%;
    .dot {
      height: 15px;
      width: 15px;
      margin-right: 5px;
      border-radius: 50%;
      border: 1px solid rgba(76, 76, 76, .5);
      display: inline-block;
    }
  }
}
.ant-calendar-panel {
  .ant-calendar-range-middle {
    padding: 0;
  }
  .ant-calendar-date-input-wrap {
    .ant-calendar-input {
      text-align: center;
    }
  }
  .ant-calendar-range-right {
    .ant-calendar-date-input-wrap {
      margin: 0;
    }
  }
}