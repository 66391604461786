.probe-setup-container {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  .header {
    padding: 20px 20px 0px 20px;
    height: 5%;
    margin-bottom: 30px;
  }


  .selected-probes {
    font-size: 10px;
    color: #4c4c4c;
  }
  .showing-per-page {
    font-size: 10px;
    color: rgba(76, 76, 76, .5);
  }

  .main-content {
    height: 90%;
    overflow-y: auto;
    position: relative;
  }

  .footer {

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;

    .downlaod-button{

      margin-left: 0px !important;
    }
    %flexCenter {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .footer-download-upload-buttons {
      @extend %flexCenter;

      button {
        height: auto;
        margin-left: 15px;
      }

    }

    .footer-pagination {
      @extend %flexCenter;
    }

    .footer-save-add-buttons {
      @extend %flexCenter;

      .disable-next-button{
        opacity: 0.6;
        border-color: rgba(0, 0, 0, 0.25);
        &:hover{
          background-color: #ffffff;
        }
        button{
          background-color: #ffffff;
          padding: 0;
        }
      }

      .disable-add-button{
        opacity: 0.6;
        border-color: rgba(0, 0, 0, 0.25);
        background-color: #ffffff;
        &:hover{
          background-color: #ffffff;
        }
        button{
          background-color: #ffffff;
          padding: 0;
        }
      }

      button {
        height: auto;
        width: auto;
      }
      a {
        height: auto;
      }
    }

    .add-button {
      background-color: #16A085;
      color: white;
      font-size: 15px;
      margin-left: 15px;
      //@include %flexCenter;
    }
  }

}
