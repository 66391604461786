.common-content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin: 0px 0px 20px 20px;
    border: 1px solid rgb(209, 208, 208);
    padding-bottom: 10px;
    padding-top: 20px;

    .outputs-wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        flex-direction: row;
        padding : 5px 10px;
    }

    .input-margin-right {
        margin-right: 40px;
    }

    .output-section {
        width: 100%;
    }

    .label {
        font-size: 12px;
        width: 100%;
        color: rgb(120, 120, 121);
        padding: 5px;
    }
    .right-part {
        display: flex;
        flex: 1;
        display: -webkit-flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 0px 10px;
    }
    .name-section {
        font-size: 15px;
        width: 100%;
        color: rgb(120, 120, 121);
        padding: 10px;
    }
}