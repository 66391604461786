.viewer-sidebar-container{
  border-radius: 6px;
  height: 100%;
  padding: 5px;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  position: absolute;

  .ant-tree {
    li{
      display: flex;
      align-items: center;
      width: 100%;

      .ant-tree-switcher{
        flex: 0;
        width: 15px;
        margin-right: 5px;
      }

      .ant-tree-checkbox{
        flex: 0;
      }

      .ant-tree-node-content-wrapper{
        flex: 7;
        width: 100%;
        overflow: hidden;

        .anticon{
          margin-top: 6px;
        }
        .ant-tree-title{
          width: 100%;

          .tree-node-child{
            padding-right: 20px;

            .tree-node-child-title{
              width: 100%;
              max-width: unset;
              padding: 0;
              display: block;
            }
            .tree-node-eye{
              position: absolute;
              right: 0;
            }

          }

        }
      }

      ul{
        flex: 0 0 100%;
        width: 100%;
      }

    }
  }
}
