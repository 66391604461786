
.global-settings-sidebar {
  display: flex;
  flex-direction: column;

  .global-settings-sidebar-wrap {
    position: relative;
    overflow: hidden;
    padding-bottom: 140px;
    .global-settings-sidebar-container{
      height: 100%;
      overflow-y: auto;
    }

  }

  .sidebar-tree-select {
    margin-bottom: 24px;

    .sidebar-select-tree {
      width: 100%;

      &.ant-select-open {
        .ant-select-selection {
          outline: 0;
          box-shadow: none;
        }
      }

      .ant-select-selection {
        background-color: #2980B9;
        color: #fff;
        .ant-select-arrow {
          color: #fff;
        }

      }

    }
  }

  .sidebar-filters {
    overflow: hidden;
    height: 100%;
    position: relative;

    .sidebar-filters-header {
      margin-top: -50px;
      position: absolute;
      width: 100%;

      h1 {
        font-size: 21px;
      }
    }

    .sidebar-filter-form-wrap {
      padding-top: 50px;
      height: 100%;

      .sidebar-filter-form {
        overflow: auto;
        height: 100%;
      }
    }
  }
}


.leftsidefilter {
  width: 100%;
  height: 100%;
  padding-right: 15px;

}

.sidebar-indicator-wrap {
  bottom: 30px;
}

.h1filters {
  font-weight: bold;
}

.filterform {
  width: 100%;

  .inputdiv:first-child .istyle {
    border-radius: 5px 0px 0px 0px;
  }

  .inputdiv:first-child .label-placeholder {
    border-radius: 0px 5px 0px 0px;
  }

  .inputdiv:last-child .input-filter {
    border-top: 0px;
  }

  .inputdiv:last-child .istyle {
    border-radius: 0px 0px 0px 5px;
    border-top: 0px;
  }

  .inputdiv:last-child .label-placeholder {
    border-radius: 0px 0px 5px 0px;
    border-top: 0px;
  }

  .inputdiv:not(:first-child):not(:last-child) .input-filter {
    //to do delete double line
    border-top: 0px;
  }

  .inputdiv:not(:first-child):not(:last-child) .label-placeholder {
    //to do delete double line
    border-top: 0px;
  }

  .inputdiv:not(:first-child):not(:last-child) .istyle {
    border-top: 0px;
  }
}

.calendar-img {
  border-bottom: 1px solid #7F8C8D;
  border-left: 1px solid #7F8C8D;
  border-top: 1px solid #7F8C8D;
}

.calendar {
  border-left: 1px solid #7F8C8D;
  border-bottom: 1px solid #7F8C8D;
  border-right: 1px solid #7F8C8D;

  .data-picker {
    width: 100%;
  }

  .ant-input {
    all: unset;
    text-align: center;
  }
}

.sidebar-filters-body {
  border: 1px solid #7F8C8D;
  border-radius: 5px;
}