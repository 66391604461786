.alert-setup-recipients {
  height: 100%;

  .alert-setup-recipients-header {
    height: 50px;
    .alert-setup-recipients-search {
      width: 365px;
      .alerting-search-input{
        width: 250px;
        input {
          width: 250px;
          &:focus{
            box-shadow: none;
          }
        }
      }
      button[disabled]{
        border: 1px solid #d9d9d9;
      }
    }
  }

  .alert-setup-recipients-content {
    height: 100%;
    overflow: hidden;
    padding-top: 5px;

    .recipients-list {
      height: 100%;
      overflow-y: auto;
      padding-bottom: 5px;
    }
  }

  footer {
    width: 100%;
    height: 50px;

    .footer-wrap {
      position: relative;
      display: grid;
      grid-template-columns: minmax(0, 2fr) minmax(0, 2fr) minmax(0, 2fr);
      grid-gap: 15px;
      width: 100%;

      .alert-setup-action {
        button {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            margin: 0;
            padding: 0;
          }
        }
      }

    }
  }
}

.form-contact {
  .form-body {
    padding: 15px;

    .form-body-wrap {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;

      .form-input {
        width: 260px;
        margin-bottom: 15px;

        .errMessage {
          color: rgba(231, 76, 60, 0.75);
          font-size: 10px;
        }

        .react-tel-input {
          input[type=tel] {
            width: 100%;
          }
        }

        label {
          color: #7F8C8D;
          font-size: 11px;
        }

        input {
          height: 36px;
          border-radius: 5px;

          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }

  .form-action {
    width: 100%;
    height: 50px;

    button {
      width: 100%;
      background-color: #16A085;
      color: #fff;
      height: 40px;
      border: 0;
      font-size: 16px;
      font-weight: 100;
    }
  }
}

