.probe {
  display: flex;
  flex-direction: column;
  padding: 20px 10px 20px 20px;
  &:nth-child(even) {
    background-color: #eaf4fb;
  }
  .top-side {
    display: flex;

    .top-side-checkbox {
      align-self: flex-start;
      margin: 0 17px;
      padding-top: 18px;
    }

    .probe-setup-input {
      display: flex;
      justify-content: stretch;
      width: 100%;

      .expand-probe-item{

        i{
          position: relative;
          top: 10px;
        }
      }

      .probe-inputs {
        display: flex;
        flex: fit-content;
        flex-direction: column;
        width: 100%;
        padding: 0 15px;

        label{
          font-size: 10px;
          color: #7F8C8D;
        }

        .first-probe-row {
          margin-bottom: 15px;
          display: grid;
          grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) ;
          grid-gap: 15px;



          > div:not(:last-child) {
            margin-right: 12px;
          }
        }

        .probe-details {
          transition: max-height ease-out .2s;
          overflow: hidden;
          max-height: 0;


          &.expand {
            max-height: 100%;

          }

          .second-probe-row{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 15px;
            margin-bottom: 15px;

          }
          .third-probe-row{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 2fr;
            grid-gap: 15px;
            margin-bottom: 15px;
            font-size: 14px;

            .ant-calendar-picker {
              width: 100%;
              .ant-calendar-picker-icon{
                display: none;
              }
            }
            
          }
          .tag-probe-row {
            margin-bottom: 15px;
            .ant-tag{
              background-color: #c4c4c4;
              color: #fff;
              align-items: center;
              height: 30px;
              display: inline-flex;


              i{
                color: #fff;
                font-size: 16px;
                margin-left: 10px;
              }
            }
          }

        }

      }

      .data-logger-select {

        .ant-select-selection {
          width: 400px;

        }

      }
      .ant-select{
        width: 100%;
        .ant-select-selection {
          width: 100%;

        }
      }
    }

  }

  .probe-delete-item{
    i{
      position: relative;
      top: 16px;
    }
  }

}
.add-new-data-logger {
  margin-bottom: 15px;
  height: 36px;
  cursor: pointer;
  z-index: 999999999;
}
.hide-dropdown-tag{
  display: none !important;
}

.ant-select-dropdown-menu {
  max-height: 150px!important;
}