.ant-spin-container{
  background-color: white;
}
.ant-table-row td:last-child {
  max-width: 1px!important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-table-thead tr th{
  min-width: 60px;
}
.syna-report-probe-wrap {
  width: 100%;
  padding: 10px 0;
  height: 30%;
  overflow-y: auto;
}
.syna-report-probe {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-self: flex-start;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(186, 185, 187);
  padding: 20px;
  width: 100%;
  margin-top: 20px;
}
.syna-report-probe-block {
  margin-top: 20px;
}
.syna-label {
  color: rgba(0, 0, 0, 0.45);
  font-size: 11px;
}
.syna-report-probe-content {
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: stretch;
}
.syna-report-probe-col {
  width: 100%;
  -webkit-order: 0;
  order: 0;
  -webkit-flex-grow: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
  -webkit-flex-basis: auto;
  flex-basis: auto;
  -webkit-align-self: auto;
  align-self: auto;
}
.syna-report-probe-col .syna-report-probe-block:first-child {
  margin-top: 0;
}
.ant-table-expanded-row > td:first-child{
 border-right: none !important;
}

.syna-report-subtable{
  padding: 10px 10px !important;
}
.syna-report-subtable th,td{
  padding: 0px 0px 0px 10px !important;
}
.syna-report-subtable td :first-child{
display : none;
}