.alerting-alerts-content {
  padding: 0 15px;
  height: 100%;
  overflow: hidden;

  .alerting-alerts-header {
    
    .alerting-alerts-header-wrap {

    .alerting-alerts-header-search {
      display: flex;
      justify-content: flex-start;

      .form-input {
        margin-right: 10px;

        label {
          font-size: 10px;
          color: #7f8c8d;
        }

        input {
          width: 170px;
          background-color: transparent;

          &:focus {
            box-shadow: none;
          }
        }

        .ant-select {
          width: 150px;
          
          .ant-select-selection {
            background-color: transparent;
            border: 1px solid rgba(41, 128, 185, .4);

            &:focus {
              box-shadow: none;
              border: 1px solid rgba(41, 128, 185, .7);
            }

            &:active {
              box-shadow: none;
              border: 1px solid rgba(41, 128, 185, .7);
            }

            &:hover {
              box-shadow: none;
              border: 1px solid rgba(41, 128, 185, .7);
            }

          }

          &.ant-select-open { 
            .ant-select-selection {
              box-shadow: none;
            }
          }
        }

        &.alerting-alerts-header-cta {
          padding-top: 15px;

          button {
            background-color: rgba(41, 128, 185, 1);
          }

        }

        &:nth-of-type(5) {
          flex-direction: column;
          width: 310px;
          padding: 0;

          .anticon {
            margin-right: -5px;
          }
        }

        .date-wrap {
          border: 1px solid rgba(41, 128, 185, .4);
          border-radius: 4px;
          z-index: 100; 

          .ant-calendar-range-picker-input {
            width: 140px;
          }
        }
      }
    }
  }
    .alerting-alerts-header-action {
      padding-top: 15px;
      width: 100%;
      text-align: right;

      button {
        background-color: transparent;
        padding: 0 5px;
      }
    }
  }

  .alerting-alerts-list-container {
    background-color: #fff;
    margin-top: 15px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;


    .alerting-main-checkbox {
      padding: 15px;

      .selected-alarms {
        margin-left: 10px;
      }
    }

    .alerting-alerts-list-header{
      padding: 0 15px 20px;
      span{
        font-size: 11px;
      }
    }

    .alerting-alerts-list {
      overflow-y: auto;
      height: 100%;
      .alerting-content-loader{
        width: 100%;
        padding: 0 15px;
      }
      .alerts {
        display: flex;
        flex-direction: column;
        padding: 20px 10px 20px 20px;
      }
    }

    .footer {
      position: relative;
      height: 60px;
      min-height: 60px;
    }
  }
}
.hyperlink-modal {
  .ant-modal-body {
    padding: 0 24px;
  }
  .ant-modal-footer {
    padding: 16px 24px;
  }
}



.ant-select-dropdown {
  width: 270px !important;

  div {
    width: 100%;
  } 

  .ant-select-dropdown-menu {
    max-height: 320px !important;
    overflow-y: scroll;
  }
}
