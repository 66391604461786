.name-container {
  height: 64px;
  width: 100%;
  padding: 0px 10px;
  flex-direction: row;
  justify-content: space-between;

  .name-label {
    font-size: 12px;
    margin-right: 10px;
  }

  .analysis-name {
    height: 25px;
  }
}