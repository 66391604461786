.filter-chart-container {
  flex-direction: column;
  width: 100%;
  height: 210px;
  overflow-y: hidden;
  padding: 20px;

}
.selected-analysis-container {
    background-color: rgb(255, 229, 159);
    flex-direction: column;
    flex: 1;
    width: 100%;
    padding: 10px;
  .selected-analysis-header {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    margin-bottom: 20px;
  }

  .name {
    font-size: 15px;
    flex-direction: row;

  }

  .analysis-props {
    font-size: 12px;
    margin-left: 10px;
    flex-direction: row;

  }

  .comment {
    font-size: 12px;
    margin-left: 10px;
    margin-top: 10px;
    flex-direction: row;

  }
}
