$gray_color:#7F8C8D;
.modal-form-datalogger{
  width: 500px;
  padding: 15px;
  margin: 0 auto;


  .row-form{
    display: flex;
    width: 100%;
    padding-bottom: 15px;

    .modal-form-column{
      width: 184px;
      .ant-select-selection--single {
        pointer-events: none !important;
      }
      &:first-child {
        margin-right: 15px;
      }

      .modal-form-label{
        color: #7F8C8D;
        font-size: 12px;
        .errMessage {
          color: rgba(231, 76, 60, 0.75);
          font-size: 10px;
        }
        .disableErrorMessage{
          display: none;
        }
      }

      .ant-calendar-picker {
        width: 100%;
        .ant-calendar-picker-clear{
          transition: none !important;
        }
      }
    }
  }
}

.modal-button-details {
  width: 100%;
  height: 40px;

  button{
    width: 100%;
    height: 100%;
    background-color: #16A085;
    border: 1px;
    color: #fff;
    cursor: pointer;
  }
}

.create-modal-button-details{
  height: 62px;
  padding: 15px;
  text-align: right;
  .create-modal-details-save-and-close-button{
    margin-left: 15px;
  }
  button{
    height: auto;
    font-size: 14px;
    line-height: 16px;
  }
  .hideButton{
    display: none;
  }
}
