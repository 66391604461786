.tabs {
  display: flex;
  flex-direction: row !important;
}

.tabs-left-content {
  border-left: none;
}
.chrome-picker {
  width: 235px !important;
  box-shadow: none !important;
}

.input-space {
  margin-top: 20px;
}
