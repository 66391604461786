.upload-file{
    height: auto;
}
.upload-csv-wrapper {
    width: auto;
    height: auto;
}

.upload-csv-start {
    margin-top: 16px;
}

.upload-list {
    width: 50%;
}

.list-wrap {
    width: auto;
}

.list-wrap .list-item {
    width: 100%;
    background-color: #fff;
}
.list-wrap {

    background-color: #fff;
    margin-bottom: 15px;
    padding: 10px;
}

.liste-item-description span {
    margin-right: 10px;
}

.list-wrap h5 {
    font-size: 16px;
}

.upload-form-wrap {
    width: inherit;
    height: inherit;
}
.upload-form-wrap .ant-upload.ant-upload-drag {
    background-color: #ffffff;
    border: 2px dashed rgba(0,0,0,.1);
}

.upload-form-wrap .ant-upload.ant-upload-drag img.upload-image-cover {
    width: 100%;
    max-width:200px;
}
.ant-upload-text{
    text-align: center !important;
}
.ant-upload-text button {
    max-width: 130px;
}
.ant-upload-list {
    display: none;
}

.upload-transition {
    transition: height 1s;
    -webkit-transition: height 1s;
    height: 30%;
}
.upload-transition .file-added {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: display 2s;
    height: 100%;
}

.upload-transition .ant-upload {
    padding: 15px;
}
.viewer-content .upload-transition .ant-upload.ant-upload-btn{
    padding: 0;
    display: flex;
}

.viewer-content .upload-transition .ant-upload-drag-icon {
    padding-bottom: 0;
}
.viewer-content .ant-upload.ant-upload-drag .ant-upload-btn{
    display: flex;
}
.no-added-file {
    display: flex;
    flex-direction:column;
    height: 100%;
    width: 100%;
}
.viewer-content .ant-upload-drag-container {
    width: 100%;
}
.upload-image-cover{
    width: 100% !important;
    max-height: 100px !important;

}
.upload-success {
    display: flex;
    align-items: center;
}
.upload-list-wrap {
    margin-top: 15px;
    overflow: hidden;
    height: 100%;
    padding-bottom: 50px;
}
section.header-upload-list {
    height: 50px;
    background-color: #f5f5f5;
    padding: 5px 10px;
    border-radius: 2px;
}
section.header-upload-list button {
    margin: 0;
}
section.content-upload-list {
    height: 100%;
    overflow-y: auto;
    padding: 10px;
}