.filter-view-header {
  grid-template-columns: 1fr 2fr 3fr 3fr;
  grid-template-rows: auto;
  grid-gap: 5px;
  box-decoration-break: clone;
  align-items: center;
  justify-items: center;
  width: 100%;

  .filter-view-slider {
    button{
      background-color: transparent;
      padding: 5px;

      &[ant-click-animating-without-extra-node=true]:after {
        border: 0;
      }
    }

    .anticon-calendar {
      display: none;
    }

    .anticon-close-circle {
      display: none;
    }

    input {
      padding: 0;
      font-size: 11px;
      border: 0;
      background: transparent;
      height: auto;
      text-align: center;
      color: #000;

      &[disabled] {
        color: #888;
      }

    }

    .datepicker-slider-end {
      input {
        margin-left: 5px;
      }
    }

    .ant-slider {
      width: 100%;

      .ant-slider-rail{
        background-color: #DFDFDF;
        border: 2px solid #DFDFDF;
      }

      .ant-slider-track {
        background: linear-gradient(90deg, #159957 0%, #155799 98.62%);
      }

      .ant-slider-handle {
        background-color: #007521;
        border: none;

        &:focus {
          box-shadow: none;
        }

      }
      &.ant-slider-disabled{
        .ant-slider-track {
          background: #888;
          -webkit-transition: all 1ms;
          -o-transition: all 1ms;
          transition: all 1ms;
        }
        .ant-slider-handle {
          background-color: #888;
          -webkit-transition: all 5ms;
          -o-transition: all 5ms;
          transition: all 5ms;
        }
      }
    }
  }
  .viewer-date-picker{
    max-width: 500px;
    width: 100%;
    margin: 0 auto 15px;

    .datepicker-slider-start{
      margin-right: 10px;
    }
  }
}
