.global-settings-content {
  width: 100%;
  border-radius: 5px;
  position: relative;
  margin-right: 0;
  margin-left: 0;
  transition: margin-left 400ms ease-out .5s;

}

.upload-content{
  border: 1px dashed #d9d9d9;
  width: 470px;
  max-height: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  i{
    height: 20px;
    width: 30px;
  }
  svg{
    width: 100%;
    height: 100%;
  }
}
.upload-content-with-files{
  border: 1px dashed #d9d9d9;
  width: 470px;
  max-height: 100%;
  height: 60px;
  display: flex;
  justify-content:center;
  padding: 20px;

  i{
    height: 20px;
    width: 30px;
  }
  svg{
    width: 100%;
    height: 100%;
  }

}
.hidden {
  display: none !important;
}
div.ant-modal-header {
  border-bottom: none;
}
div.ant-modal-footer {
  height: 80px;
  border-top: none;
  padding-right: 50px;
  button {
    height: 35px;
    i{
      width: 18px;
      height: 18px;
      svg{
        width: 100%;
        height: 100%;
      }
    }
  }
}
div.ant-upload-drag {
  background:inherit !important;
}
.upload-modal-content{

  max-height: 150px !important;
  width: 100%;

}
.global-settings {
  height: 100%;


  .global-settings-body {
    height: 100%;
    overflow: hidden;
    padding: 15px;
    margin-left: 0;
    position: relative;




    .js-modal-animation {
      .backdrop {
        opacity: 1;
      }

    }

    &.js-handle-global-settings-sidebar {


      .global-settings-content {
        margin-left: 415px;
      }

      .sidebar-wrap {
        left: 0;
        height: 100%;
        overflow: hidden;
      }

    }

    .sidebar-wrap {
      position: absolute;
      left: -450px;
      width: 415px;
      transition: left 200ms ease-in .5s;
      height: 100%;

      .global-settings-sidebar {
        position: absolute;
        padding: 15px 15px 30px;
        width: 100%;
        height: 100%;

        .global-settings-sidebar-grid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
          grid-gap: 5px;

        }
        .global-settings-indicator {
          position: absolute;
          bottom: 30px;

          .indicator-container {
          }
        }
      }
    }

    .global-settings-content {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      overflow: hidden;
      margin-right: 0;
      margin-left: 0;
      transition: margin-left 500ms ease-out .1s;


      .tree-view-container-wrap {
        padding: 15px;
      }
      .tree-setup-view-container-info-modal {
        background: rgba(0, 0, 0, 0.5);
        z-index: 99;
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 1;
        visibility: visible;

        &.js-tree-setup-view-container-info-hide {
          opacity: 0;
          visibility: hidden;
        }

      }

      .tree-view-sort-container {
        height: 100%;

        .tree-view-sort {
          overflow: hidden;
          height: 100%;
          padding-bottom: 86px;
          .header-view-sort{
            display: flex;
            justify-content: space-between;
            h2 {
              font-size: 21px;
              text-transform: uppercase;
            }
            .description-icon-info{
              z-index: 1101;
            }
             .tree-setup-info-description{
               visibility: hidden;
               position: absolute;
               right: 0;
               top: 0;
               padding: 15px 15px 25px 15px;
               width: 460px;
               height: 250px;
               white-space: normal;
               -webkit-transition: visibility 0s, opacity 0.3s linear;
               transition: visibility 0s, opacity 0.3s linear;
               border-radius: 6px 0 6px 6px;

               .tree-setup-info-description-content-no-data{
                 display: flex;
                 position: relative;
                 word-wrap: break-word;
                 height: 72%;
                 justify-content: center;
                 align-items: center;
                 
                 p {
                   width: 100%;
                 }

                 .tree-setup-no-description{
                   display: flex;
                   flex-direction: column;
                   align-items: center;
                   justify-content: center;
                 }

                 .ant-input {
                   height: 100%;
                 }

                 textarea {
                   resize: none;
                 }
               }

               .tree-setup-info-description-content{
                 display: flex;
                 position: relative;
                 word-wrap: break-word;
                 height: 72%;
                 overflow: auto;
                 overflow-x: hidden;

                 p {
                   width: 100%;
                 }
                 .ant-input {
                    height: 100%;
                  }

                  textarea {
                    resize: none;
                  }
               }

               .tree-setup-description-edit-button {
                 margin-top: 10px;
               }
             }

            .js-tree-setup-info-description-show{
              visibility: visible;
              background-color: #FFFFFF;
              z-index: 1100;
              .tree-setup-header-description-info{
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
            }
          }
          .btn-tree-delete {
            color: #E74C3C;
            position: relative;
            top: 1px;
          }

          .rstcustom__rowContents {
            height: 45px;
            box-shadow: none;
            border-radius: 5px;
            border-color: #7F8C8D;
          }

          .ReactVirtualized__Grid__innerScrollContainer {

            .rst__node {

              &:first-child {

                .rst__nodeContent {

                  &::before {
                    content: '';
                    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAyMCAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjU3OCAwLjUzMjg5NEwxOC44NTQ5IDguODEwMUMxOS4zODE3IDkuMzM2NjMgMTkuMzgxNyAxMC4xOTAzIDE4Ljg1NDkgMTAuNzE2NkMxOC4zMjg2IDExLjI0MjkgMTcuNDc1IDExLjI0MjkgMTYuOTQ4NyAxMC43MTY2TDkuNjI0ODYgMy4zOTI1NEwyLjMwMTI5IDEwLjcxNjRDMS43NzQ3NiAxMS4yNDI3IDAuOTIxMjE0IDExLjI0MjcgMC4zOTQ4OTcgMTAuNzE2NEMtMC4xMzE2MzIgMTAuMTkwMSAtMC4xMzE2MzIgOS4zMzY0MiAwLjM5NDg5NyA4LjgwOTg5TDguNjcxOTYgMC41MzI2ODFDOC45MzUyNSAwLjI2OTUyMiA5LjI3OTk1IDAuMTM4MDkyIDkuNjI0ODIgMC4xMzgwOTJDOS45Njk4NiAwLjEzODA5MiAxMC4zMTQ4IDAuMjY5Nzc4IDEwLjU3OCAwLjUzMjg5NFoiIGZpbGw9IiM3RjhDOEQiLz4KPC9zdmc+') no-repeat center;
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    top: 17px;
                    left: 6px;
                    background-size: 13px;
                  }


                  .rst__collapseButton {
                    left: 21px !important;
                    z-index: 1;
                    background: #fff url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAyMCAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjU3OCAwLjUzMjg5NEwxOC44NTQ5IDguODEwMUMxOS4zODE3IDkuMzM2NjMgMTkuMzgxNyAxMC4xOTAzIDE4Ljg1NDkgMTAuNzE2NkMxOC4zMjg2IDExLjI0MjkgMTcuNDc1IDExLjI0MjkgMTYuOTQ4NyAxMC43MTY2TDkuNjI0ODYgMy4zOTI1NEwyLjMwMTI5IDEwLjcxNjRDMS43NzQ3NiAxMS4yNDI3IDAuOTIxMjE0IDExLjI0MjcgMC4zOTQ4OTcgMTAuNzE2NEMtMC4xMzE2MzIgMTAuMTkwMSAtMC4xMzE2MzIgOS4zMzY0MiAwLjM5NDg5NyA4LjgwOTg5TDguNjcxOTYgMC41MzI2ODFDOC45MzUyNSAwLjI2OTUyMiA5LjI3OTk1IDAuMTM4MDkyIDkuNjI0ODIgMC4xMzgwOTJDOS45Njk4NiAwLjEzODA5MiAxMC4zMTQ4IDAuMjY5Nzc4IDEwLjU3OCAwLjUzMjg5NFoiIGZpbGw9IiM3RjhDOEQiLz4KPC9zdmc+') no-repeat center;
                    box-shadow: none;
                    top: 51%;
                    background-size: 13px 7px;
                  }

                  .rst__expandButton {
                    left: 21px !important;
                    background: #fff url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAyMCAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguNjcyMDMgMTAuNzE2NkwwLjM5NTA4OCAyLjQzOTQxQy0wLjEzMTY5NyAxLjkxMjg4IC0wLjEzMTY5NyAxLjA1OTIgMC4zOTUwODggMC41MzI5MjFDMC45MjE0MDQgMC4wMDY2MDMzOSAxLjc3NTA0IDAuMDA2NjAzNDMgMi4zMDEzMSAwLjUzMjkyMUw5LjYyNTE0IDcuODU2OTdMMTYuOTQ4NyAwLjUzMzEzNUMxNy40NzUyIDAuMDA2ODE3MDIgMTguMzI4OCAwLjAwNjgxNzA2IDE4Ljg1NTEgMC41MzMxMzVDMTkuMzgxNiAxLjA1OTQ1IDE5LjM4MTYgMS45MTMwOSAxOC44NTUxIDIuNDM5NjJMMTAuNTc4IDEwLjcxNjhDMTAuMzE0OCAxMC45OCA5Ljk3MDA1IDExLjExMTQgOS42MjUxOCAxMS4xMTE0QzkuMjgwMTQgMTEuMTExNCA4LjkzNTE4IDEwLjk3OTcgOC42NzIwMyAxMC43MTY2WiIgZmlsbD0iIzdGOEM4RCIvPgo8L3N2Zz4K') no-repeat center;
                    box-shadow: none;
                    background-size: 13px 7px;
                    top:34px;

                    &:hover:not(:active){
                      width: 30px;
                      height: 16px;
                    }



                  }
                }


                .rst__lineHalfHorizontalRight {
                  &::before {
                    height: 0;
                  }


                }


                .rst__toolbarButton {
                  display: none;
                }

                .rst__moveHandle {
                  display: none;
                }

                .rst__row {
                  padding-left: 42px;
                }
              }

              .rst__collapseButton{
                background: #fff url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAyMCAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjU3OCAwLjUzMjg5NEwxOC44NTQ5IDguODEwMUMxOS4zODE3IDkuMzM2NjMgMTkuMzgxNyAxMC4xOTAzIDE4Ljg1NDkgMTAuNzE2NkMxOC4zMjg2IDExLjI0MjkgMTcuNDc1IDExLjI0MjkgMTYuOTQ4NyAxMC43MTY2TDkuNjI0ODYgMy4zOTI1NEwyLjMwMTI5IDEwLjcxNjRDMS43NzQ3NiAxMS4yNDI3IDAuOTIxMjE0IDExLjI0MjcgMC4zOTQ4OTcgMTAuNzE2NEMtMC4xMzE2MzIgMTAuMTkwMSAtMC4xMzE2MzIgOS4zMzY0MiAwLjM5NDg5NyA4LjgwOTg5TDguNjcxOTYgMC41MzI2ODFDOC45MzUyNSAwLjI2OTUyMiA5LjI3OTk1IDAuMTM4MDkyIDkuNjI0ODIgMC4xMzgwOTJDOS45Njk4NiAwLjEzODA5MiAxMC4zMTQ4IDAuMjY5Nzc4IDEwLjU3OCAwLjUzMjg5NFoiIGZpbGw9IiM3RjhDOEQiLz4KPC9zdmc+') no-repeat center;
                background-size: 12px;
                outline: 0;

                &:hover:not(:active){
                  width: 16px;
                  height: 16px;
                }

                &:focus {
                  box-shadow: 0 0 0 1px #000;
                }

              }
              .rst__expandButton {
                background: #fff url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAyMCAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguNjcyMDMgMTAuNzE2NkwwLjM5NTA4OCAyLjQzOTQxQy0wLjEzMTY5NyAxLjkxMjg4IC0wLjEzMTY5NyAxLjA1OTIgMC4zOTUwODggMC41MzI5MjFDMC45MjE0MDQgMC4wMDY2MDMzOSAxLjc3NTA0IDAuMDA2NjAzNDMgMi4zMDEzMSAwLjUzMjkyMUw5LjYyNTE0IDcuODU2OTdMMTYuOTQ4NyAwLjUzMzEzNUMxNy40NzUyIDAuMDA2ODE3MDIgMTguMzI4OCAwLjAwNjgxNzA2IDE4Ljg1NTEgMC41MzMxMzVDMTkuMzgxNiAxLjA1OTQ1IDE5LjM4MTYgMS45MTMwOSAxOC44NTUxIDIuNDM5NjJMMTAuNTc4IDEwLjcxNjhDMTAuMzE0OCAxMC45OCA5Ljk3MDA1IDExLjExMTQgOS42MjUxOCAxMS4xMTE0QzkuMjgwMTQgMTEuMTExNCA4LjkzNTE4IDEwLjk3OTcgOC42NzIwMyAxMC43MTY2WiIgZmlsbD0iIzdGOEM4RCIvPgo8L3N2Zz4K') no-repeat center;
                background-size: 12px;
                outline: 0;
                background-position-y: 5px;

                &:hover:not(:active){
                  width: 16px;
                  height: 16px;
                }

                &:focus {
                  box-shadow: 0 0 0 1px #000;
                }
              }


              .rst__rowWrapper {

                .rst__row {
                  border: 1px solid #7F8C8D;
                  border-radius: 5px;
                  width: 380px;
                  overflow: hidden;

                  &.rst__rowLandingPad {
                    &::before{
                      background-color: #fff;
                      border: 1px dashed rgba(0,0,0,.5);
                      border-radius: 6px;
                    }
                  }

                  &.rst__rowCancelPad{
                    &::before {
                      background-color:rgba(230, 168, 173, .2);
                    }
                  }

                  .rst__moveHandle {
                    border: none;
                    box-shadow: none;
                    background: #fff url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjN0Y4QzhEIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+') no-repeat center;

                  }

                  .rst__rowContents {
                    background-color: transparent;
                    border: none;
                    box-shadow: none;
                    padding: 0;

                    .rst__rowToolbar {
                      position: absolute;
                      right: 10px;
                    }

                    .rst__rowLabel {
                      width: 100%;
                      height: 100%;
                      padding-right: 34px;
                      background-color: #ffffff;

                      .rst__rowTitle {
                        width: 100%;
                        height: 100%;
                        display: flex;

                        input {
                          border: 0;
                          font-size: 14px;
                          width: 100%;

                          &:focus {
                            outline: none;
                          }
                        }

                      }

                    }

                  }

                }

              }
            }
          }

        }

        .tree-view-actions {
          position: relative;
          width: 100%;

          .tree-view-actions-container {
            position: absolute;
            bottom: 0;
            width: 100%;

            button {
              margin-left: 15px;
            }
          }

        }

      }


      .global-settings-content-no-data-screen {
        width: 100%;
        height: 100%;

        img {
          height: 200px;
          margin-bottom: 20px;
        }
        .content {
          display: flex;

          button {
            display: flex;
            flex-direction: row;
            margin-top: 30px;
            justify-items: center;
            align-items: center;

            i {
              margin: 0px 5px;
            }
          }
        }

      }

    }

  }
}
