.energy-analysis-menu-wrapper {
  flex-direction: column;
  display: flex;
  margin: 15px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(186, 185, 187);
  padding: 50px 80px 50px 80px;
  justify-content: space-around;
  align-self: flex-start;
}

.energy-analysis-menu-wrapper .title {
  font-size: 20px;
  padding: 20px 0 40px 0;
}

.energy-analysis-menu-wrapper .process {
  justify-content: space-between;
  align-content: center;
  padding-bottom: 40px;
}

.energy-analysis-menu-wrapper .process-wrapper {
  width: 150px;
  height: 150px;
  border-radius: 15px;
  flex-direction: column;
}

.energy-analysis-menu-wrapper .process-wrapper:hover {
  background-color: #eee;
}

.energy-analysis-menu-wrapper .process-text {
  font-size: 16px;
}
