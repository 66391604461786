.tab-indicator-wrap{
  width: 186px;
  height: 42px;
  position: relative;
  padding: 5px 10px;
  border-radius: 5px;

  .blue {
    background-color: blue;
  }

  .tab-indicator {
    color: #4c4c4c;

    i {
      margin-right: 5px;
      width: 21px;
    }
  }

  &.active {
    background: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  .indicator-status{
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  .selected-route{
    background-color: white;
    font-size: 50px;
  }
}
