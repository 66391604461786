.alerting-header {
  .alerting-header-container {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 5px;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    align-items: center;
    width: 100%;

    .alerting-header-title {
      h4 {
        font-size: 21px;
        text-transform: uppercase;
      }
    }

    .alerting-header-action{
      a{
        width: 190px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: none;
        // background-color: #ffffff;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        background-color: transparent;
        box-shadow: none;

        &.active-top-bar{
          // background-color: transparent;
          // box-shadow: none;
          background-color: #ffffff;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        }

        i{
          margin-right: 5px;
        }

        &:hover{
          color: #7d8084;
          path{
            fill:#7d8084
          }
        }
      }
    }
  }
}