@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mqtt-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .header {
    //padding-left: 20px;
    padding: 0 0 15px 20px;
  }

  .main-content {
    overflow-y: auto;
  }

  .footer {
    height: 60px;
    min-height: 60px;
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .footer-download-upload-buttons {
      display: flex;
      width: 250px;
    }

    .footer-pagination {
      @include flexCenter;
    }

    .footer-save-add-buttons {
      width: 250px;
      display: flex;
      justify-content: flex-end;
    }

    Button {
      font-size: 14px;
      line-height: 19px;
      border: 2px solid #16A085;
      box-sizing: border-box;
      border-radius: 6px;
      color: #16A085;
      margin-left: 15px;

      &:last-child {
        margin-right: 15px;
      }
    }

    .add-button {
      background-color: #16A085;
      color: #FFFFFF;
      font-size: 15px;
    }
  }
}