.mqtt-item-container{
  display: flex;
  flex-direction: column;
  padding: 20px 20px  32px 20px;

  &:nth-child(even) {
    background-color: #eaf4fb;
  }

  .mqtt-item-column {
    display: flex;
    .mqtt-item-first-row{
      -webkit-align-self: flex-start;
      align-self: flex-start;
      margin-right: 17px;
      padding-top: 5px;
    }
    .mqtt-item-second-row {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: stretch;
      justify-content: stretch;
      width: 100%;
      .mqtt-pointer{
        padding-top: 5px;
      }
      .mqtt-inputs{
        display: -webkit-flex;
        display: flex;
        -webkit-flex: fit-content 1;
        flex: fit-content 1;
        -webkit-flex-direction: column;
        flex-direction: column;
        width: 100%;
        padding: 0 15px;

        .mqtt-inputs-first-row{
          display: grid;
          grid-template-columns: 3fr 3fr 1fr 2fr 2fr 2fr;
          grid-gap: 15px;
          margin-bottom: 15px;
        }
        .mqtt-inputs-secound-row{
          display: grid;
          grid-template-columns: 2fr 2fr 3fr 3fr;
          grid-gap: 15px;
          margin-bottom: 15px;
        }
      }
      .mqtt-item-third-column-wrapping-input{
        display: inherit;

        .no-data-label {
          color: #7F8C8D;
          font-style: italic;
        }

        label{
          color: #7F8C8D;
          font-size: 12px;
        }

        .mqtt-item-paragraf{
            color: #000000;
            align-self: center;
          .mqtt-channel-preview-icon{
            margin-right: 5px;
          }

        }
      }
    }
  }
}