.backdrop {
  background-color: rgba(0, 0, 0, .7);
  -webkit-transition: background-color 3s ease-out;
  transition: background-color 3s ease-out;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;

  .modal {
    background-color: #fff;
    border-radius: 6px;
    width: 500px;
    height: 470px;
    padding: 15px;
    position: relative;
    overflow: hidden;
    transform: translateY(0px) scaleX(1) scaleY(1) translateX(0px) rotateZ(0deg);
    transform-origin: 50% 50%;
    line-height: 1;

    .input-label-with-counter{
      .form-input-count{
        font-size: 10px;

        &.form-input-count-error{
          color: #e74c3c;
        }
      }
    }

    .close-modal {
      position: absolute;
      right: 15px;
      cursor: pointer;

      .close-modal-icon {
        color: #e74c3c;

        &:hover {
          color: rgba(231, 76, 60, .8);
        }
      }
    }
    form {
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h2 {
        font-size: 16px;
        margin-top: 20px;
        padding-left: 15px;
        color: #7F8C8D;

        &:last-child{
          margin-top: 20px;
        }
      }

      .custom-checkbox{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 50px;
      }

      .input-field {
        display: flex;
        flex-direction: column;
        color: #4C4C4C;
        opacity: 0.5;
        padding: 0 50px;
        margin-bottom: 10px;

        label{
          margin: 10px 0;
          display: flex;
          font-size: 12px;
          span {
            margin: 0 8px;
          }
        }
        textarea {
          padding: 12px 16px;
          resize: none;
          color: #4C4C4C;
          font-size: 16px;
          border: 1px solid #7F8C8D;
          box-sizing: border-box;
          border-radius: 5px;
          margin: 0;
        }

        input {
          padding: 12px 16px;
          border: 1px solid #7F8C8D;
          box-sizing: border-box;
          border-radius: 5px;
          margin: 0;
        }
      }


      .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        left: 0;
        right: 0;

        button {
          width: 100%;
          height: auto;
          background: #16A085;
          border-radius: 0 0 6px 6px;
          color: #FFFFFF;
          font-size: 16px;
          line-height: 25px;
          padding: 10px;
          margin: 0;
          border:none;

          i{
            margin-right: 5px;
          }

        }
      }
    }
  }
}