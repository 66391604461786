.viewers-details {
  height: 100%;
  padding: 15px;

  &.js-dashboard-info-modal {
    .dashboard-view-info-description{
      opacity: 1;
      visibility: visible;
    }

  }

  .viewer-info-modal {
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 1;
    visibility: visible;

    &.js-viewer-info-modal-hide {
      opacity: 0;
      visibility: hidden;
    }

  }

  .viewer-chart-wrap{
    width: 100%;
    position: relative;
    display: flex;
    height: 100%;

  }
  &.js-show-viewer-sidebar{

    .js-show-right-sidebar{

      .right-sidebar-wrap {
        right: 275px;
      }
    }

    .viewers-details-body{
      .viewer-chart-wrap {
        margin-left: 10px;
      }
    }

    .dashboard-or-details-left-sidebar-wrap {
      left: 0;
      width: 300px;
      min-width: 300px;
    }
  }

  .viewer-chart-container{
    display: flex;
    width: 100%;
    height: 100%;
    margin-right: 15px;
  }

  .viewers-details-body {
    height: 100%;
    overflow: hidden;
    margin-left: 0;
    position: relative;
    padding: 0;

    .js-show-right-sidebar{
      .viewers-details-content{
        margin-right: 15px;
        position: relative;
        >div{
          overflow-y: hidden;
        }
      }
      .right-sidebar-wrap {
        right: 0;
      }
    }

    .right-sidebar-wrap{
      position: relative;
      right: 0;
      //width: 350px;
      height: 100%;
    }
    .viewers-details-content {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      overflow: hidden;
      margin-right: 0;
      margin-left: 0;
      position: absolute;

    }

  }
}

.viewer-details-wrapper {
  height: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;

  .viewer-details-body{
    height: 100%;
    display: block;
    overflow: hidden;

    .viewer-details-wrap{
      grid-template-columns: 0 5fr 0;
      grid-template-rows: auto;
      grid-gap: 5px;
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
      align-items: center;
      display: grid;
      height: 100%;
      .left-sidebar-wrapper{
        width: 100%;
      }

      >div{
        height: 100%;
        position: relative;
      }

      .viewer-details-content-wrapper{
        .chart-wrap{
          height: 100%;
          position: relative;
          .viewers-details-content{
            height: 100%;
            width: 100%;
            padding: 10px;
            display: flex;
            flex-direction: column;
            position: absolute;
            > div {
              height: 70%;
            }
          }
        }
      }
      .viewer-details-right-sidebar-wrapper{

      }

    }

  }

  &.viewer-show-left-sidebar{
    .left-sidebar-wrapper{
      opacity: 1;
    }
    .viewer-details-wrap{
      grid-template-columns: 2fr 10fr 0;
    }
    &.viewer-show-right-sidebar{
      .viewer-details-wrap{
        grid-template-columns: 3fr 12fr 3fr;
      }
    }
  }
  &.viewer-show-right-sidebar{
    .viewer-details-right-sidebar-wrapper{
      opacity: 1;
    }
    .viewer-details-wrap{
      grid-template-columns: 0 10fr 2fr;
    }
  }

  .left-sidebar-wrapper{
    opacity: 0;
    -o-transition: opacity 0.5s ease-out;
    -moz-transition: opacity 0.5s ease-out;
    -webkit-transition: opacity 0.5s ease-out;
    transition: opacity 0.5s ease-out;
  }
  .viewer-details-right-sidebar-wrapper{
    opacity: 0;
  }


  .viewer-details-header-wrapper {
    grid-template-columns: 1fr 2fr 3fr 3fr;
    grid-template-rows: auto;
    grid-gap: 5px;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    align-items: center;
  }

  //.viewer-details-content-wrapper {
  //  grid-area: content;
  //}
  //.viewer-details-left-sidebar-wrapper {
  //  grid-area: left;
  //}
  //.viewer-details-right-sidebar-wrapper {
  //  grid-area: right;
  //  border-radius: 6px;
  //  margin-right: 2px;
  //  padding: 10px;
  //}
  @media (max-width: 500px) {
    .viewer-details-wrapper {
      grid-template-columns: 1fr 3fr 1fr;
      grid-template-areas:
              "header"
              "content"
              "left"
              "right";
    }
  }
  .hidden-content {
    display: none;
  }
}
