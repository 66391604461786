.selected-filter-container-wrapper {
  flex-direction: column;
  width: 100%;
  overflow-y: hidden;
  padding: 10px;

  .selected-filter-container-header {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    margin-bottom: 20px;

    .active-filter-name {
      font-size: 20px;
    }
  }
}
