@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}
.csv-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .csv-main-checkbox {
    padding: 0 0 15px 20px;
  }

  .csv-main-content {
    overflow-y: auto;

    .csv-main-content-order {
      padding: 20px 20px 32px 20px;
      display: grid;
      grid-template-columns: 0fr 1fr 1fr 1fr 1fr;
      grid-gap: 15px;

      &:nth-child(even) {
        background-color: #eaf4fb;
      }
      .no-data-label {
        color: #7F8C8D;
      font-style: italic;
      }

      .csv-checkbox {
        padding-top: 20px;
      }

      div {
        flex-grow: 1;
        margin-bottom: 10px;

        &:first-child {
          flex-grow: 0;
        }
      }

      label {
        color: #7F8C8D;
        font-size: 10px;
      }

      p {
        font-size: 14px;
        color: black;
        overflow: hidden;

      }
    }
  }

  .csv-footer {
    height: 60px;
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 60px;

    .footer-download-upload-buttons {
      display: flex;
      width: 250px;
    }

    .footer-pagination {
      @include flexCenter;
    }

    .footer-save-add-buttons {
      width: 250px;
      display: flex;
      justify-content: flex-end;
    }

    Button {
      font-size: 14px;
      line-height: 19px;
      border: 2px solid #16A085;
      box-sizing: border-box;
      border-radius: 6px;
      color: #16A085;
      margin-left: 15px;

      &:last-child {
        margin-right: 15px;
      }
    }

    .add-button {
      background-color: #16A085;
      color: white;
      font-size: 15px;
    }
  }
}
