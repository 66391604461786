.alerts-item {
  padding: 10px 15px;

  &:nth-child(2n) {
    background-color: #eaf4fb;
  }

  .alerts-item-wrap {

    .alerts-item-checkbox {
      margin-right: 10px;
      margin-top: 10px;
    }

    .accordion__icon {
      margin-left: auto;
      transition: transform 0.6s ease;
      cursor: pointer;

      &.rotate {
        transform: rotate(-180deg);
      }
    }

    .alerts-item-content {
      width: 100%;

      .alerts-item-icon {
        margin-top: 11px;
        margin-right: 10px;
      }

      .alerts-item-data {
        width: 100%;

        .alerts-data-visible-on-load {
          display: grid;
          grid-template-columns: minmax(0, 3fr) minmax(0, 2fr) minmax(0, 2fr) minmax(0, 2fr) minmax(0, 1fr);
          grid-gap: 10px;
        }

        .alerts-data-hidden-on-load {
          overflow: hidden;
          transition: max-height 0.6s ease;
          display: grid;
          grid-template-columns: minmax(0, 3fr) minmax(0, 3fr) minmax(0, 3fr);
          grid-gap: 10px;
          padding-top: 10px;
        }

        .alerts-group {

          label {
            color: #7F8C8D;
            font-size: 9px;
          }

          p {
            font-size: 13px;

            &.strong-text {
              font-weight: bold;
            }
            &.status-text{
              padding-right: 15px;
            }
            .status-dot{
              width: 15px;
              height: 15px;
              background: #7a7a7a;
              border-radius: 50%;
              margin-right: 5px;
              display: block;

            }
          }
          .hyperlink-link {
            color: #1890ff;
            font-size: 13px;
            cursor: pointer;
          }
        }
      }
    }
  }
}