.not-found-wrapper{
  height:100%;
  width: 100%;
  .no-found-action{
    margin-right: 70px;
    h1{
      font-size: 70px;
    }
    h4{
      font-size: 50px;
      span{
        color: #16a085;
      }
    }
    p{
      font-size: 24px;
    }

    .go-home-button{
      margin-top: 50px;
      background-color: #16a085;
      color: #fff;
      width: 190px;
      height: 50px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;

    }
  }
  .no-found-graph{
    img{
      max-width: 600px;
      max-height: 400px;
    }
  }
}