.main-content {
    width: 100%;

    .main-content-left-sidebar {
        height: 100%;
        position: relative;
        overflow: hidden;
        width: 100%;
        margin-left: 0;
        max-width: 450px;
        transition: margin-left .3s;
        min-width: 350px;
    }

    .main-content-viewer {

        overflow: hidden;
        display: block;
        position: relative;
        width: 100%;
        height: 100%;

        .main-content-viewer-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding-right: 250px;
            height: 100%;
            overflow-x: unset;
            overflow-y: auto;
        }
        .main-content-viewer-container-hide {
            display: flex;
            flex-direction: column;
            overflow-x: unset;
            overflow-y: auto;
        }
    }


    @media screen and (min-width: 1025px) and (max-width: 1280px) {
        .main-content-left-sidebar {
            max-width: 350px;
        }
    }
}

.hide-sidebar {

    .main-content-left-sidebar {
        margin-left: -388px;
    }
    @media screen and (min-width: 1025px) and (max-width: 1280px) {
        .main-content-left-sidebar {
            margin-left: -292px;
        }
    }
}