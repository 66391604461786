.active-filter-wrapper {
  width: 100%;
  height: 100px;
  overflow-y: auto;
  padding: 10px 5px;

  .active-filter {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2px 20px;
    cursor: pointer;
  }

  .selected-filter {
    width: 100%;
    background-color: #f2f2f2;
  }

  .name {
    font-size: 15px;
  }
}