
.ant-modal-wrap {

  .data-logger-upload-csv {
    padding-bottom: 0;
    border-radius: 6px;
    width: 574px !important;
    height: 400px;

    .ant-modal-content {
      border-radius: 6px;

      .list-item {
        position: relative;

        .list-item-header {
          margin-bottom: 10px;

          h5 {
            font-size: 14px;
            color: #4c4c4c;
          }

          span {
            font-size: 12px;
            color: #7f8c8d
          }
        }

        .item-close {
          padding: 0;
          position: absolute;
          right: -50px;
          top: -6px;

          button {
            margin: 0;
            background: none;
            opacity: 0;
          }
        }

        &:hover {
          .item-close {
            button{
              color: rgba(231, 76, 60, .7);
              opacity: 1;
            }
          }
        }

        .list-item-description {
          font-size: 12px;
          color: #7f8c8d;

          .item-type {
            margin-right: 15px;


          }

        }
      }


      .ant-modal-close {

        .ant-modal-close-x {
          height: auto;
          width: auto;

          i {
            font-size: 12px;
            position: absolute;
            top: 10px;
            right: 10px;
            font-weight: 700;
            color: rgba(231, 76, 60, 1);

            &:hover {
              color: rgba(231, 76, 60, .7);
            }
          }
        }
      }

      .ant-modal-header {
        margin-bottom: 40px;

        .ant-modal-title {
          font-size: 21px;
        }
      }

      .ant-modal-body {
        font-size: 14px;
        line-height: 1.5;
        word-wrap: break-word;
        width: 390px;
        margin: 0 auto;
        padding: 0;

        .upload-modal-content{

        }

        .list-wrap {
          position: relative;
          padding: 15px 0;
        }

        .upload-content {
          height: 270px;
          width: 390px;
          margin: 0 auto;
          border-radius: 5px;
          border-color: #7F8C8D;
          cursor: pointer;


          i {
            width: 100px;
            height: auto;
            color: #B0B0B0;
          }

          p {
            font-size: 14px;
            font-weight: 100;
          }
        }

        .ant-upload-select {
          display: block;

          .upload-content-with-files {
            width: 100%;
            align-items: center;
            border-radius: 5px;
            border-color: #7F8C8D;


            i {
              width: 33px;
              height: 25px;
              color: #b0b0b0;
              margin-right: 10px;
            }

            p {
              font-size: 14px;
              color: rgba(76, 76, 76, .5);

            }
          }
        }
        .upload-modal-content{

        }

      }

      .ant-modal-footer {
        padding: 25px 25px 20px;

        button {
          height: auto;
        }

      }
    }
  }
}
