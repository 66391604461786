$smut_white:#F7F9FA;
.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transform: scale(1);
  opacity: 1;
  z-index: 999;

  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 574px;
    border-radius: 6px;
    overflow: hidden;
    .card-container {
      margin-top: -5px;
      .ant-tabs{background-color: #fff}
      .ant-tabs-card {
        .ant-tabs-bar{
          background: transparent;
          .ant-tabs-nav-container{
              div {
                background-color: #F7F9FA ;
              }
            }
              .ant-tabs-tab{
               background-color: #F7F9FA !important;
                .tabPaneSpanDisable{
                  opacity: 0.5;
                }
                .tabPaneSpanEnable{
                  opacity: 1;
                }
                &:last-child{
                  &.ant-tabs-tab-active {
                    background: #fff !important;
                    border-radius: 6px 0 0 0 !important;

                  }
                }
                &.ant-tabs-tab-active {
                  background: #fff !important;
                  border-radius: 6px 6px 0 0 !important;



                }
              }
        }
      }
    }

    .modal-header {
      width: 100%;
      height: 10%;
      padding: 3%;
      background-color: $smut_white;
      display: inline-flex;

      h1{
        font-size: 21px;
      }

      .modal-header-icon {
        flex: 1;
        text-align: right;
      }
    }
  }
}
