.alert-setup-alerts {
  height: 100%;


  .alert-setup-alerts-header {
    width: 100%;
    height: 50px;
    .alert-setup-alerts-search{
      width: 365px;
      .alerting-search-input{
        width: 250px;
        input {
          width: 250px;
          &:focus{
            box-shadow: none;
          }
        }
      }
      button[disabled]{
        border: 1px solid #d9d9d9;
      }
    }
  }

  .alert-setup-alerts-content {
    height: 100%;
    overflow: hidden;
    padding-bottom: 5px;

    .alerts-list {
      overflow-y: auto;
      height: 100%;
      position: relative;
      padding-bottom: 5px;
    }
  }

  footer {
    width: 100%;
    height: 50px;

    .footer-wrap {
      position: relative;
      display: grid;
      grid-template-columns: minmax(0, 2fr) minmax(0, 2fr) minmax(0, 2fr);
      grid-gap: 15px;
      width: 100%;

      .alert-setup-action {
        button {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            margin: 0;
            padding: 0;
          }
        }
      }

    }

    .modal-status {
      div {
        &:last-of-type {
          overflow: inherit;
        }
      }
    }
  }
}


.form-template {
  .form-body {
    padding: 15px;

    .form-body-wrap {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;

      .form-input {
        width: 260px;
        margin-bottom: 15px;

        &.error-message{
          margin-bottom: 0;

          .warning-text {
            color: rgba(231, 76, 60, 0.75);
            font-size: 12px; 
          }
        }


        .ant-select-selection--multiple {
         

          .ant-select-selection__rendered {
            line-height: 34px;

            ul {
              li {
                height: 28px;
                line-height: 26px;

                .ant-select-selection__choice__content {
                  font-size: 12px;
                }
              }
            }
          }
        }

        .ant-select-selection--single {
          height: 36px;

          .ant-select-selection__rendered {
            line-height: 34px;
          }
        }

        input[type=checkbox]:checked {
          background-color: #7F8C8D;
        }

        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: #7F8C8D;
            border-color: #768384;
          }
        }

        label {
          color: #7F8C8D;
          font-size: 11px;
        }

        input {
          height: 36px;
          border-radius: 5px;

          &.ant-select-search__field {
            height: 100%;
            margin-left: 5px;
          }

          &:focus {
            box-shadow: none;
          }
        }

        .rta {
          height: 100px;

          .rta__autocomplete {
            border-radius: 5px !important;

            .rta__list {
              font-size: 13px;

              .rta__entity {
                padding: 2px 5px;
              }
            }
          }
        }
      }
    }
  }

  .form-action {
    width: 100%;
    height: 40px;

    button {
      width: 100%;
      background-color: #16A085;
      color: #fff;
      height: 40px;
      border: 0;
      font-size: 16px;
      font-weight: 100;
    }
  }
}
