.tree-node-wrap {
  width: 100%;
  overflow: hidden;
}

.tree-node-wrap li ul {
  padding: 0 0 0 5px;
}

.tree-node-wrap li ul li .ant-tree-node-content-wrapper {
  display: flex ;
  display: -webkit-flex;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  align-items: center;
  -webkit-align-items: center;
}

span.ant-tree-title {
  width: 290px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.tree-node-wrap li ul li {
  display: unset;
}
.ant-tree.ant-tree-directory {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tree-node-wrap .tree-node-child {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
  -webkit-align-items: center;
}

.ant-tree.ant-tree-directory.tree-node-wrap
> li.ant-tree-treenode-selected
> span.ant-tree-node-content-wrapper,
.ant-tree.ant-tree-directory.tree-node-wrap
.ant-tree-child-tree
> li.ant-tree-treenode-selected
> span.ant-tree-node-content-wrapper {
  color: #222;
}

.ant-tree.ant-tree-directory.tree-node-wrap
> li.ant-tree-treenode-selected
> span.ant-tree-node-content-wrapper:before,
.ant-tree.ant-tree-directory.tree-node-wrap
.ant-tree-child-tree
> li.ant-tree-treenode-selected
> span.ant-tree-node-content-wrapper:before {
  background: #f2f2f2;
}


.ant-tree.ant-tree-directory.tree-node-wrap
> li
span.ant-tree-switcher.ant-tree-switcher-noop,
.ant-tree.ant-tree-directory.tree-node-wrap
.ant-tree-child-tree
> li
span.ant-tree-switcher.ant-tree-switcher-noop {
  display: inline;
}
.ant-tree.ant-tree-directory > li span.ant-tree-switcher,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-switcher {
  float: left;
}

span.tree-node-child-action {
  width: 70px;
  height: 100%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  z-index: 99999;
}

.tree-node-child-color {
  width: 20px;
  height: 2px;
  margin-right: 5px;
}
.tree-node-child-color-hide {
  display: none;
}
.tree-node-child .anticon:not(:first-child) {
  margin-left: 5px;
}

.react-contexify {
  z-index: 99999;
}

.antd-context-menu {
  z-index: 99999;
}

.ant-tree li ul li .ant-tree-node-content-wrapper {
  display: flex !important;

}
.syna-tree-body {
  overflow-x: hidden;
}

.ant-tree li{
  width: 99%;
  display:flex;
}
span.ant-tree-node-content-wrapper {
  display: flex !important;
}
.ant-tree li ul li .ant-tree-title {
  width: 90%;
  display:flex;
}
.ant-tree li ul li li .ant-tree-title {
  width: 87.5%;
  display:flex;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ant-tree li ul li li span .tree-node-child-title {
  display:flex;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: scroll;
  white-space: nowrap;
  text-overflow: clip;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
ul.ant-tree-child-tree {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.ant-tree li ul li .ant-tree-title > .tree-node-child {
  width: inherit;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
}

span.tree-node-child-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 275px;
  display: flex;
  box-sizing: border-box;
}

li.ant-tree-treenode-switcher-open {
  flex-wrap: wrap ;
}
li.ant-tree-treenode-switcher-close > ul {
  display: none;
}
li.ant-tree-treenode-switcher-open > ul.ant-tree-child-tree-open {
  flex-direction:column ;
  flex-wrap: wrap ;
}
li.ant-tree-treenode-switcher-close > ul.ant-tree-child-tree-close {
 display: none;
}
li.ant-tree-treenode-switcher-open ul.ant-tree-child-tree-open li.ant-tree-treenode-switcher-open{
  display: flex
}
li.ant-tree-treenode-switcher-close ul.ant-tree-child-tree-close li.ant-tree-treenode-switcher-close{
  display: none
}

span.ant-tree-node-content-wrapper{
  width : 275px;
  box-sizing: border-box;
}

.tree-loader{
  height: 100%;
  display: flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
}
