.filter-view-details-wrapper {
  height: 100%;
  width: 100%;
  padding: 0 15px 15px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;

  .filter-left-sidebar-wrapper{
    width: 100%;
    position: relative;
    @media screen and (max-width: 1441px) {
     // width: 300px;
    }
    .filter-left-sidebar-container {
      position: absolute;
      border-radius: 6px;
      margin-right: 15px;
      height: 100%;
      padding: 5px;
      width: 100%;
      overflow: hidden;
      overflow-y: auto;

      @media screen and (max-width: 1441px) {
      //  width: 300px;
      }
      .ant-tree {
        li{
          display: flex;
          align-items: center;
          width: 100%;

          .ant-tree-switcher{
            flex: 0;
            width: 15px;
            margin-right: 5px;
          }

          .ant-tree-checkbox{
            flex: 0;
          }

          .ant-tree-node-content-wrapper{
            flex: 7;
            width: 100%;
            overflow: hidden;

            .anticon{
              margin-top: 6px;
            }
            .ant-tree-title{
              width: 100%;

              .tree-node-child{
                padding-right: 20px;

                .tree-node-child-title{
                  width: 100%;
                  max-width: unset;
                  padding: 0;
                  display: block;
                }
                .tree-node-eye{
                  position: absolute;
                  right: 0;
                }

              }

            }
          }

          ul{
            flex: 0 0 100%;
            width: 100%;
          }

        }
      }
    }
  }

  .filter-view-details-header-wrapper {
    grid-template-columns: 1fr 2fr 3fr 3fr;
    grid-template-rows: auto;
    grid-gap: 5px;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    width: 400px;
  }
  .filter-view-details-body{
    height: 100%;
    display: flex;
    overflow: hidden;
    width: 100%;

    .filter-view-details-wrap{
      grid-template-columns: 3fr 11fr 3fr;
      grid-template-rows: auto;
      grid-gap: 5px;
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
      align-items: center;
      display: grid;
      height: 100%;
      width: 100%;


      >div{
        height: 100%;
      }

      .filter-view-details-content-wrapper {
        width: 100%;
        position: relative;
        .filter-view-chart-container {
          width: 100%;
          position: relative;
          .chart-wrap {
            height: 100%;
            .filters-view-details-content {
              height: 100%;
              width: 100%;
              padding: 10px;
              position: absolute;
            }
          }
        }
      }
      .filter-view-details-right-sidebar-wrapper{
        padding: 10px;
        width: 100%;
      }
    }

  }
  .hidden-content {
    display: none;
  }
}
