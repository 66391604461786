.sidebar-form-filter {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;

  .sidebar-filters-header {

    .sidebar-filters-header-wrap {

      .disable-apply-button{
        opacity: 0.6;
        border-color: rgba(0, 0, 0, 0.25);
        button{
          background-color: #ffffff;
          padding: 0;
          border: none;
        }
      }
    }
  }
  .ant-calendar-range-picker-input {
    width: 25%;
  }
}

