.header-wrapper {
    width: 100%;
    flex-direction: row;
    height: 50px;

    .tab {
        flex: 1;
        justify-content: space-between;
        padding-left: 10px;
    }

    .header-title {
        font-size: 20px;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
    }

    .no-border {
        border: none !important;
    }
}
