.is-active{
  border-radius: 5px;
  border: 1px solid #16a085;
}
.btn-unlock-lock-save{
  padding: 1px 2px !important;

  .ant-btn {
    padding: 1px 2px;
  }
}
.chart-item {

  position: relative;
  overflow: hidden;
  z-index: 97;
  &:hover{
    opacity: 1 !important;
  }
  .ant-btn[disabled]{
    background-color: #fff;
  }
  .dashboard-view-plot-chart-header{
    padding: 5px 15px;
    z-index: 1;

    .dashboard-view-drag-handle{
      cursor: grab;
      margin-right: 10px;
      position: relative;
    }
    .dashboard-view-plot-chart-header-div-title {
      width: 100%;
      marginRight: 30px;


      p {
        font-size: 13px;
        color: #272727;
        font-weight: 500;
      }
    }

    button{
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      i{
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .dashboard-view-plot-chart{
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 40px 15px 40px;
    background-color: rgba(255,255,255,1);
    border-radius: 6px;

    >div {
      height: 100%;
    }
  }

  .dashboard-view-plot-date-range-picker {
    width: 100%;
    position: absolute;
    padding: 0 15px 10px;
    bottom: 0;

    button{
      &:focus{
        color: #7a7a7a;
      }
      &:hover{
        color: #7a7a7a;
      }
    }


    .anticon-calendar {
      display: none;
    }

    .anticon-close-circle {
      display: none;
    }
    .unlock-widget{
      //color: #40a9ff;
      background-color: #fff;
      //border-color: #40a9ff;
      &.focus{
        color:#7a7a7a;
      }
    }

    .unlock-widget:active {
      color: #ffffff;


    }

    .lock-widget{
      color: rgba(0, 0, 0, 0.65);
    }
    input {
      padding: 0;
      font-size: 11px;
      border: 0;
      background: transparent;
      height: auto;
      text-align: center;
      color: #000;
      text-align: right;

      &[disabled] {
        color: #888;
      }

    }


    .datepicker-slider-end {
    //   text-align: left;
    //   min-width: 130px !important;
    //   input {
    //     margin-left: 0;
    //   }
    }

    .ant-slider {
      width: 100%;

      .ant-slider-rail {
        background-color: #DFDFDF;
        border: 2px solid #DFDFDF;
      }

      .ant-slider-track {
        background: linear-gradient(90deg, #159957 0%, #155799 98.62%);
      }

      .ant-slider-handle {
        background-color: #007521;
        border: none;

        &:focus {
          box-shadow: none;
        }

      }

      &.ant-slider-disabled {
        .ant-slider-track {
          background: #888;
          -webkit-transition: all 1ms;
          -o-transition: all 1ms;
          transition: all 1ms;
        }

        .ant-slider-handle {
          background-color: #888;
          -webkit-transition: all 5ms;
          -o-transition: all 5ms;
          transition: all 5ms;
        }
      }
    }
  }
}
