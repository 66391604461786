
  .ant-modal-wrap {

    .dashboard-modal {
      .ant-modal-content {
        overflow: hidden;

        .ant-modal-body {
          padding: 15px;

          .create-new-dashboard-header {

            .create-new-dashboard-header-title {
              font-size: 21px;
              line-height: 20px;
              font-weight: 500;
            }

            .anticon-close {
              font-size: 12px;
              font-weight: 700;
              color: #E74C3C;
            }

          }

          .create-new-dashboard-content-form {
            padding: 50px 60px;

            .form-input-count {
              font-size: 9px;
              color: #aaa;
              font-weight: 100;

              &.form-input-count-error {
                color: #E74C3C;
              }

            }

            .form-input-field {
              margin-bottom: 30px;

              &:last-child {
                margin-bottom: 0;
              }

              label {
                font-size: 11px;
                color: #7F8C8D;

                #display-error-message {
                  color: rgba(231, 76, 60, 0.75);
                  margin-left: 10px;
                }

              }

              input {
                &.error-input {
                  border: 1px solid #E74C3C;
                }
              }

            }

          }
        }

        .ant-modal-footer {

          padding: 0;
          height: 50px;


          .create-new-dashboard-button {
            width: 100%;
            height: 100%;
            border-radius: 0;
            color: #ffffff;
            font-size: 18px;

            &:hover {
              opacity: .8;
              background-color: #16a085;
            }

            &:focus {
              background-color: #16a085;
            }

          }

        }

      }

    }
  }
