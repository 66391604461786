.triggers-item{
    padding-bottom: 25px;
    padding-top: 10px;
    position: relative;
    padding-left: 15px;
    .triggers-item-content{
        width: 100%;
    
        .triggers-item-action{
            width: 20px;
            position: relative;
            align-items: center;

            .button-icon {
                background:none!important;
                color: #110AFF;
                border:none;
                margin-top: 3px;
                padding:2px;
                font: inherit;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                span {
                    justify-content: center;
                    margin:1px;
                    z-index: 99;
                    background: #4C4C4C;
                    border-radius: 50%;
                    height: 5px;
                    width: 5px;
                    color: #4C4C4C;
                }
                &.ant-dropdown-open{
                    span {
                        background: #2980B9;
                    }
                }
            }
            .ant-dropdown {
                top: 0 !important;
                left: 0 !important;
                width: 150px;
                z-index: 98;
        
                ul {
                    box-shadow: none;
                    border: 1px solid #2980B9;
                    border-radius: 6px;
                    padding-top: 0;
                    padding-bottom: 0;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
            
                    li {
                        border-bottom: 1px solid #2980B9;
                        padding: 0;
                        display: block;
                        height: 30px;
                        position: relative;
                        overflow: hidden;
                
                        a{
                            padding-left: 20px;
                            text-align: left;
                            display: block;
                            top: 0;
                        }
                
                        &:last-child {
                            border: 0;
                        }
                
                        button {
                            background-color: transparent;
                            width: 100%;
                            height: 100%;
                            padding-left: 20px;
                            text-align: left;
                        }
                    }
                }
            }
        }

        .triggers-item-data{
            width: 100%;
            display: grid;
            grid-template-columns: 2fr 4fr 2fr 2fr 2fr 2fr;
            grid-template-rows: auto;
            grid-gap: 10px;
            .triggers-group{
                label {
                    color: #7F8C8D;
                    font-size: 9px;
                }
        
                p {
                    font-size: 13px;
        
                    &.strong-text {
                    font-weight: bold;
                    }
                    &.status-text{
                    padding-right: 15px;
                    }
                    .status-dot{
                    width: 15px;
                    height: 15px;
                    background: #7a7a7a;
                    border-radius: 50%;
                    margin-right: 5px;
                    display: block;
                    }
                }

            }
        }
    }
}