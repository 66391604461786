$white_color:#FFFFFF;
$gray_black_color:#2E3032;
$gray_color:#7F8C8D;



.create-new-datalogger-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transform: scale(1);
  opacity: 1;
  z-index: 999;

  .create-new-datalogger-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 574px;
    border-radius: 6px;
    overflow: hidden;
    .create-new-datalogger-modal-card-container {
      margin-top: -5px;
      background-color: #fff;

      .ant-tabs-card {

        .ant-tabs-bar {
          background: #F7F9FA;
          border: 0;
          height: 22px;
          margin: 0;

          .ant-tabs-nav-container {
            height: 22px;
            margin-top: -1px;

            .ant-tabs-nav-wrap{
              background-color: transparent;
              .ant-tabs-nav-scroll{
                text-align: right;
              }

            }

            .ant-tabs-nav {

              > div {
                text-align: right;
                background-color: #F7F9FA ;
              }

              .ant-tabs-tab {
                background-color: #F7F9FA !important;
                border: none !important;
                height: 22px;
                line-height: 22px;
                color: #4C4C4C;
                font-weight: 300;
                font-size: 12px;
                margin: 0;
                border-radius: 0px !important;
                .tabPaneSpanDisable{
                  opacity: 0.5;
                }
                .tabPaneSpanEnable{
                  opacity: 1;
                }
                &:last-child{
                  &.ant-tabs-tab-active {
                    background: #fff !important;
                    border-radius: 6px 0 0 0 !important;
                  }
                }
                &.ant-tabs-tab-active {
                  background: #fff !important;
                  border-radius: 6px 6px 0 0 !important;
                }
                &.ant-tabs-tab-active {
                  border-color: #fff;
                  background: transparent;
                  color: #4C4C4C;
                  font-weight: 300;
                }

              }
            }
          }
        }

        .ant-tabs-content {
          .ant-tabs-tabpane {
            padding: 0;
          }
        }
      }
    }

    .data-logger-consumer{
      border: 0;
      padding: 0;
      color: #7F8C8D;

      &.stop{
        color: rgba(229, 19, 25, 0.58);
      }

    }

    .create-new-datalogger-modal-header {
      width: 100%;
      height: 10%;
      padding: 3%;
      background-color: #F7F9FA;
      display: inline-flex;

      h1{
        font-size: 21px;
      }
      .modal-header-icon {
        flex: 1;
        text-align: right;
      }
    }
  }
}
