.alert-setup-triggers {
  height: 100%;

  .alert-setup-triggers-header {
    height: 50px;
    .alert-setup-triggers-search {
      width: 365px;
      .alerting-search-input{
        width: 250px;
        input {
          width: 250px;
          &:focus{
            box-shadow: none;
          }
        }
      }
      button[disabled]{
        border: 1px solid #d9d9d9;
      }
    }
  }

  .alert-setup-triggers-content {
    height: 100%;
    overflow: hidden;
    padding-top: 5px;
    
    .alerts-list {
      overflow-y: auto;
      height: 100%;
      position: relative;
      padding-bottom: 5px;
    }
  }

  footer {
    width: 100%;
    height: 50px;

    .footer-wrap {
      position: relative;
      display: grid;
      grid-template-columns: minmax(0, 2fr) minmax(0, 2fr) minmax(0, 2fr);
      grid-gap: 15px;
      width: 100%;

      .alert-setup-action {
        button {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            margin: 0;
            padding: 0;
          }
        }
      }

    }

    .modal-status {
      div {
        &:last-of-type {
          overflow: inherit;
        }
      }
    }
  }
}

.form-alerts {
  .form-body {
    padding: 15px;

    .form-body-wrap {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;

      .form-input {
        width: 260px;
        margin-bottom: 15px;

        &.error-message{
          margin-bottom: 0;

          .warning-text {
            color: rgba(231, 76, 60, 0.75);
            font-size: 12px; 
          }
        }
        .warning-message{
          color: #7F8C8D;
          font-size: 10px;
          font-weight: 500;
        }

        .add-new-node{
          border: 0;
          width: 20px;
          min-width: 20px;
          height: 20px;
          background-color: rgba(127, 140, 141, .25);
          color: #fff;
          border-radius: 3px;
          padding: 0;
          align-items: center;
          display: flex;
          justify-content: center;
          cursor: pointer;
          i{
            font-size: 11px;
          }

          &:hover{
            background-color: #7F8C8D;
          }

        }

        .node-list{
          flex-wrap: wrap;
          max-height: 220px;
          overflow-y: scroll;
          .node-item{
            margin: 3px 5px 3px 0px;
            background-color: rgba(127, 141, 140, .2);
            padding: 2px 5px;
            border-radius: 3px;
            font-size: 10px;
          }
        }

        .form-input-group {
          &.short-input {
            width: 100px;

            input {
              padding: 10px !important;
            }
          }
          &.duration-field{
            width: 150px;
          }
          &.select-duration-time{
            .ant-radio-group{
              label{
                height: 36px;
                margin-top: 16px;
                line-height: 32px;
              }
            }
            .ant-radio-button-wrapper-checked {
              color: #fff;
            }
          }

          &.dropdown-button {
            margin-top: 4px;
            .ant-select {
              .ant-select-selection--single {
                height: 36px;
                box-shadow: none;

                .ant-select-selection__rendered {
                  line-height: 36px;
                }

                .ant-select-selection-selected-value {
                  line-height: 36px;
                }
              }
              margin-top: 1px;
            }
          }
        }


        label {
          color: #7F8C8D;
          font-size: 11px;
        }

        input {
          height: 36px;
          border-radius: 5px;

          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }


  .form-action {
    width: 100%;
    height: 40px;

    button {
      width: 100%;
      background-color: #16A085;
      color: #fff;
      height: 40px;
      border: 0;
      font-size: 16px;
      font-weight: 100;
    }
  }
}

.ant-drawer-content-wrapper {
  width: 280px!important;
  .ant-tree-switcher-noop {
    width: 0px!important;
  }
  .ant-tree {
    li{
      display: flex;
      align-items: center;
      width: 100%;

      .ant-tree-switcher{
        flex: 0;
        width: 15px;
        margin-right: 5px;
      }

      .ant-tree-checkbox{
        flex: 0;
      }
      .ant-tree-node-content-wrapper{
        flex: 7;
        width: 100%;
        overflow: hidden;
      }
    }
    ul{
      flex: 0 0 100%;
      width: 100%;
    }
  }

  .ant-tree-node-content-wrapper {
    width: 100%!important;
    overflow: hidden;
    padding: 0!important;

    .ant-tree-title {
      position: inherit!important;
      width: 100%!important;
  
      .tree-node-child {
        width: 100%!important;
    
        .tree-node-child-title {
          width: 100%;
          max-width: unset;
          padding: 0;
          display: block;
        }
        .tree-node-eye {
          width: 0;
        }
      }
    }
  }

}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}