.fullscreen{
  height: 100%;

  &.fullscreen-enabled  {
    background: #f2f5f7;
  }

  .notification-bar{
    position: absolute;
    right: 15px;
    bottom: 15px;
    z-index: 98;
    .ant-alert-message{
      margin-right: 15px;
    }
  }

  .main-viewer {
    height: 100%;
    overflow: hidden;
    padding: 15px;
    margin-left: 0;
    position: relative;
    -webkit-transition: all .25s linear;
    -o-transition: all .25s linear;
    transition: all .25s linear;

    .dashboard-info-modal {
      background: rgba(0, 0, 0, 0.5);
      z-index: 99;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 1;
      visibility: visible;


      -webkit-transition: all .25s;
      -o-transition: all .25s;
      transition: all .25s;

      &.js-dashboard-info-modal-hide {
        opacity: 0;
        visibility: hidden;
        .dashboard-view-info-description{
          opacity: 0;
          visibility: hidden;
        }
      }

    }

    .main-viewer-wrap-close {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      overflow: hidden;
      margin-right: 0;
      margin-left: 0;
      transition: margin-left 500ms ease-out .1s;

        &.js-show-sidebar{

          .dashboard-view-left-sidebar{
            left: 0px;
          }
        }
        .dashboard-view-left-sidebar{
          position: relative;
          height: 100%;
          left: -350px;
          -webkit-transition: left 0.15s ease-in-out 1ms;
          -o-transition: left 0.15s ease-in-out 1ms;
          transition: left 0.15s ease-in-out 1ms;

          .dashboard-view-sidebar-container{
            position: absolute;
          }

        }

      .dashboard-view-container {
        width: 100%;
        height: 100%;
        margin-left: 0;
        -webkit-transition: margin-left 0.15s ease-in-out 1ms;
        -o-transition: margin-left 0.15s ease-in-out 1ms;
        transition: margin-left 0.15s ease-in-out 1ms;

        .dashboard-view-container-grid{
          display: grid;
          grid-template-columns: repeat(12, 1fr);
          grid-template-rows: repeat(12, 1fr);
          grid-auto-flow: row;
          grid-gap: 15px;
          height: 100%;
          -webkit-transition: all .25s  cubic-bezier(.46,.35,0,1.25);
          -o-transition: all .25s  cubic-bezier(.46,.35,0,1.25);
          transition: all .25s cubic-bezier(.46,.35,0,1.25);

          [class*='layout-col-'] {
            &.empty-field{
              button{
                width: 100%;
                height: 100%;
                background-color: #eceff1;
                border: 1px dashed #ddd;
                border-radius: 5px;
                &:focus{
                  outline: none;
                }
                i{
                  width: 50px;
                  height: 50px;
                  background-color: #dee1e3;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin: 0 auto;
                  border-radius: 50%;
                }
              }
            }

          }

          &.layout-1 {
            -webkit-transition: all .45s  linear;
            -o-transition: all .45s  linear;
            transition: all .45s linear;


            .layout-col-1-6 {
              grid-column: 1/5;
              grid-row: 1/7;

            }
            .layout-col-2-6{
              grid-column: 5/9;
              grid-row: 1/7;
            }
            .layout-col-3-6{
              grid-column: 9/13;
              grid-row: 1/7;
            }
            .layout-col-4-6{
              grid-column: 1/5;
              grid-row: 7/13;
            }
            .layout-col-5-6{
              grid-column: 5/9;
              grid-row: 7/13;
            }
            .layout-col-6-6{
              grid-column: 9/13;
              grid-row: 7/13;
            }
            .layout-col-7{
              grid-column: 9/13;
              grid-row: 13;
            }
          }
          &.layout-2 {

            .layout-col-1-6 {
              grid-column: 1/13;
              grid-row: 1/4;
            }
            .layout-col-2-6{
              grid-column: 1/7;
              grid-row: 4/10;
            }
            .layout-col-3-6{
              grid-column: 7/13;
              grid-row: 4/7;
            }
            .layout-col-4-6{
              grid-column: 7/13;
              grid-row: 7/10;
            }
            .layout-col-5-6{
              grid-column: 1/13;
              grid-row: 10/13;
            }
            .layout-col-7{
              grid-column: 9/13;
              grid-row: 13;
            }

          }
          &.layout-3 {

            .layout-col-1-6 {
              grid-column: 1/13;
              grid-row: 1/4;
            }
            .layout-col-2-6{
              grid-column: 1/7;
              grid-row: 4/10;
            }
            .layout-col-3-6{
              grid-column: 7/13;
              grid-row: 4/10;
            }
            .layout-col-4-6{
              grid-column: 1/13;
              grid-row: 10/13;
            }
            .layout-col-7{
              grid-column: 9/13;
              grid-row: 13;
            }

          }
          &.layout-4 {

            .layout-col-1-6 {
              grid-column: 1/13;
              grid-row: 1/4;
            }
            .layout-col-2-6{
              grid-column: 1/5;
              grid-row: 4/10;
            }
            .layout-col-3-6{
              grid-column: 5/9;
              grid-row: 4/10;
            }
            .layout-col-4-6{
              grid-column: 9/13;
              grid-row: 4/10;
            }
            .layout-col-5-6{
              grid-column: 1/13;
              grid-row: 10/13;
            }
            .layout-col-7{
              grid-column: 9/13;
              grid-row: 13;
            }
          }
          &.layout-5 {

            .layout-col-1-6 {
              grid-column: 1/13;
              grid-row: 1/4;
            }
            .layout-col-2-6{
              grid-column: 1/5;
              grid-row: 4/10;
            }
            .layout-col-3-6{
              grid-column: 5/13;
              grid-row: 4/10;
            }
            .layout-col-4-6{
              grid-column: 1/13;
              grid-row: 10/13;
            }
            .layout-col-7{
              grid-column: 9/13;
              grid-row: 13;
            }
          }
          &.layout-6 {

            .layout-col-1-6 {
              grid-column: 1/13;
              grid-row: 1/4;
            }
            .layout-col-2-6{
              grid-column: 1/13;
              grid-row: 4/10;
            }
            .layout-col-3-6{
              grid-column: 1/13;
              grid-row: 10/13;
            }
            .layout-col-7{
              grid-column: 9/13;
              grid-row: 13;
            }
          }
          &.layout-7 {
            .layout-col-1-6 {
              grid-column: 1/13;
              grid-row: 1/13;
            }
            .layout-col-7{
              grid-column: 9/13;
              grid-row: 13;
            }
          }
          &.layout-8 {
            .layout-col-1-6 {
              grid-column: 1/7;
              grid-row: 1/13;
            }
            .layout-col-2-6 {
              grid-column: 7/13;
              grid-row: 1/13;
            }
            .layout-col-7{
              grid-column: 9/13;
              grid-row: 13;
            }
          }
          &.layout-9 {
            .layout-col-1-6 {
              grid-column: 1/7;
              grid-row: 1/7;
            }
            .layout-col-2-6 {
              grid-column: 7/13;
              grid-row: 1/7;
            }
            .layout-col-3-6 {
              grid-column: 1/7;
              grid-row: 7/13;
            }
            .layout-col-4-6 {
              grid-column: 7/13;
              grid-row: 7/13;
            }
            .layout-col-7{
              grid-column: 9/13;
              grid-row: 13;
            }
          }
        }
      }

      .modal-widgets {
        [class*=layout-col-] {
          z-index : 99999999999
        }
      }
    }




    .main-viewer-wrap {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      overflow: hidden;
      margin-right: 0;
      margin-left: 0;
      transition: margin-left 500ms ease-out .1s;
      display: grid;
      grid-template-columns: 2fr 10fr;
      grid-gap: 5px;

      .dashboard-view-left-sidebar{
        width: 100%;
        position: relative;
      }
      .dashboard-view-container {
        width: 100%;
        height: 100%;
        margin-left: 0;
        -webkit-transition: margin-left 0.15s ease-in-out 1ms;
        -o-transition: margin-left 0.15s ease-in-out 1ms;
        transition: margin-left 0.15s ease-in-out 1ms;

        .dashboard-view-container-grid{
          display: grid;
          grid-template-columns: repeat(12, 1fr);
          grid-template-rows: repeat(12, 1fr);
          grid-auto-flow: row;
          grid-gap: 15px;
          height: 100%;
          -webkit-transition: all .25s  cubic-bezier(.46,.35,0,1.25);
          -o-transition: all .25s  cubic-bezier(.46,.35,0,1.25);
          transition: all .25s cubic-bezier(.46,.35,0,1.25);

          [class*='layout-col-'] {
            &.empty-field{
              button{
                width: 100%;
                height: 100%;
                background-color: #eceff1;
                border: 1px dashed #ddd;
                border-radius: 5px;
                &:focus{
                  outline: none;
                }
                i{
                  width: 50px;
                  height: 50px;
                  background-color: #dee1e3;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin: 0 auto;
                  border-radius: 50%;
                }
              }
            }

          }

          &.layout-1 {
            -webkit-transition: all .45s  linear;
            -o-transition: all .45s  linear;
            transition: all .45s linear;


            .layout-col-1-6 {
              grid-column: 1/5;
              grid-row: 1/7;

            }
            .layout-col-2-6{
              grid-column: 5/9;
              grid-row: 1/7;
            }
            .layout-col-3-6{
              grid-column: 9/13;
              grid-row: 1/7;
            }
            .layout-col-4-6{
              grid-column: 1/5;
              grid-row: 7/13;
            }
            .layout-col-5-6{
              grid-column: 5/9;
              grid-row: 7/13;
            }
            .layout-col-6-6{
              grid-column: 9/13;
              grid-row: 7/13;
            }
            .layout-col-7{
              grid-column: 9/13;
              grid-row: 13;
            }
          }
          &.layout-2 {

            .layout-col-1-6 {
              grid-column: 1/13;
              grid-row: 1/4;
            }
            .layout-col-2-6{
              grid-column: 1/7;
              grid-row: 4/10;
            }
            .layout-col-3-6{
              grid-column: 7/13;
              grid-row: 4/7;
            }
            .layout-col-4-6{
              grid-column: 7/13;
              grid-row: 7/10;
            }
            .layout-col-5-6{
              grid-column: 1/13;
              grid-row: 10/13;
            }
            .layout-col-7{
              grid-column: 9/13;
              grid-row: 13;
            }

          }
          &.layout-3 {

            .layout-col-1-6 {
              grid-column: 1/13;
              grid-row: 1/4;
            }
            .layout-col-2-6{
              grid-column: 1/7;
              grid-row: 4/10;
            }
            .layout-col-3-6{
              grid-column: 7/13;
              grid-row: 4/10;
            }
            .layout-col-4-6{
              grid-column: 1/13;
              grid-row: 10/13;
            }
            .layout-col-7{
              grid-column: 9/13;
              grid-row: 13;
            }

          }
          &.layout-4 {

            .layout-col-1-6 {
              grid-column: 1/13;
              grid-row: 1/4;
            }
            .layout-col-2-6{
              grid-column: 1/5;
              grid-row: 4/10;
            }
            .layout-col-3-6{
              grid-column: 5/9;
              grid-row: 4/10;
            }
            .layout-col-4-6{
              grid-column: 9/13;
              grid-row: 4/10;
            }
            .layout-col-5-6{
              grid-column: 1/13;
              grid-row: 10/13;
            }
            .layout-col-7{
              grid-column: 9/13;
              grid-row: 13;
            }
          }
          &.layout-5 {

            .layout-col-1-6 {
              grid-column: 1/13;
              grid-row: 1/4;
            }
            .layout-col-2-6{
              grid-column: 1/5;
              grid-row: 4/10;
            }
            .layout-col-3-6{
              grid-column: 5/13;
              grid-row: 4/10;
            }
            .layout-col-4-6{
              grid-column: 1/13;
              grid-row: 10/13;
            }
            .layout-col-7{
              grid-column: 9/13;
              grid-row: 13;
            }
          }
          &.layout-6 {

            .layout-col-1-6 {
              grid-column: 1/13;
              grid-row: 1/4;
            }
            .layout-col-2-6{
              grid-column: 1/13;
              grid-row: 4/10;
            }
            .layout-col-3-6{
              grid-column: 1/13;
              grid-row: 10/13;
            }
            .layout-col-7{
              grid-column: 9/13;
              grid-row: 13;
            }
          }
          &.layout-7 {
            .layout-col-1-6 {
              grid-column: 1/13;
              grid-row: 1/13;
            }
            .layout-col-7{
              grid-column: 9/13;
              grid-row: 13;
            }
          }
          &.layout-8 {
            .layout-col-1-6 {
              grid-column: 1/7;
              grid-row: 1/13;
            }
            .layout-col-2-6 {
              grid-column: 7/13;
              grid-row: 1/13;
            }
            .layout-col-7{
              grid-column: 9/13;
              grid-row: 13;
            }
          }
          &.layout-9 {
            .layout-col-1-6 {
              grid-column: 1/7;
              grid-row: 1/7;
            }
            .layout-col-2-6 {
              grid-column: 7/13;
              grid-row: 1/7;
            }
            .layout-col-3-6 {
              grid-column: 1/7;
              grid-row: 7/13;
            }
            .layout-col-4-6 {
              grid-column: 7/13;
              grid-row: 7/13;
            }
            .layout-col-7{
              grid-column: 9/13;
              grid-row: 13;
            }
          }
        }
      }
    }
  }

}
