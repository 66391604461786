.active-analysis-wrapper {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 15px 0px;

    .analysis {
        align-items: center;
        justify-content: space-between;
        padding: 2px 20px;
        cursor: pointer;
    }

    .selected-analysis {
        width: 100%;
        background-color: #f2f2f2;
    }

    .name {
        font-size: 15px;
    }
}
