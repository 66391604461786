.alerts-item {
    .alerts-item-action {
        width: 20px;
        position: relative;
        align-items: center;
    
        .button-icon {
            background: none !important;
            color: #110AFF;
            border: none;
            margin-top: 3px;
            padding: 2px;
            font: inherit;
            cursor: pointer;
            display: flex;
            flex-direction: column;
    
            span {
            justify-content: center;
            margin: 1px;
            z-index: 99;
            background: #4C4C4C;
            border-radius: 50%;
            height: 5px;
            width: 5px;
            color: #4C4C4C;
    
            }
            &.ant-dropdown-open{
            span {
                background: #2980B9;
            }
            }
        }
    }
    .alerts-data-view {
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 2fr) minmax(0, 2fr) minmax(0, 3fr) minmax(0, 2fr) minmax(0, 2fr);
        grid-gap: 15px;
        width: 100%;
        padding-top: 2px;

        input[type=checkbox]:checked {
        background-color: #7F8C8D;
        }

        .ant-checkbox-checked {
            .ant-checkbox-inner {
                background-color: #7F8C8D;
                border-color: #768384;
            }
        }

        label {
            font-size: 10px;
            color: #7F8C8D;
        }

        p {
            line-height: 1;
            .colorPreview{
                width: 14px;
                height: 14px;
                display: block;
                border-radius: 50%;
                position: relative;
                top: -1px;
            }
            &.alerts-position{
                margin: 0 10px;
                display: block;
                width: 40px;
                text-align: center;
                font-weight: 600;
                color: #000;
            }
            &.alerts-status{
                font-weight: 600;
                color: #000;
            }
        }
        .alerts-arrow-disabled{
            path{
                fill: rgba(0,0,0,.5);
            }
        }
    }
}