.calculator-wrapper {
  width: 100%;
  padding: 20px;
  margin-left: 20px;
  border: 0.5px solid rgb(209, 208, 208);

  .calculator-shorcuts-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  .calculator-function-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    text-align: center;
    border: 0.5px solid rgb(209, 208, 208);
    height: 50px;
    font-size: 16px;
    padding: 10px;
    border-radius: 8px;
  }

  .calculator-function-wrapper:hover {
    background-color: rgb(209, 208, 208);
  }

  .selected-calcuator-probe-color {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    text-align: center;
    background-color: rgb(231, 237, 241);
    border: 0.5px solid rgb(209, 208, 208);
    height: 50px;
    padding: 10px;
    border-radius: 8px;
  }

  .selected-calcuator-probe-color:hover {
    background-color: rgb(209, 208, 208);
  }
}
