
.sidebar-container {
  width: 100%;
  height: 100%;
  border-right: 1px solid;
  left:0;
  position: relative;
  transition: left 0.25s ease-in-out 0s;

  .sidebar-container-box {
    height: 100%;
    overflow: hidden;
    width: 100%;

    // The search
    .sidebar-search {
      height: 95px;
      width: 100%;
      min-height: 95px;
      border-bottom: 1px solid;

      .sidebar-search-input{
        width: 100%;
        padding: 15px;
      }

      .sidebar-button-js{
        width: 70px;
        border-left: 1px solid;
        cursor: pointer;

        .sidebar-button-container{
          position: relative;
          top:20px
        }
      }
    }
    // Tree view
    .sidebar-tree-view {
      height: 100%;
      overflow: hidden;
      display: block;
    }

    // Footer
    .sidebar-footer{
      height: 84px;
    }
  }
}