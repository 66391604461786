$white_color:#FFFFFF;
.modal-form-ftp{
  background-color: $white_color;
  width: 500px;
  padding: 20px;
  margin: 0 auto;

  .row-form{
    display: flex;
    width: 100%;
    margin-bottom: 15px;
    .ftp-password-column{
      margin-left: 150px;
    }
    .modal-form-column {
      width: 184px;
      .modal-form-label{
        color: #7F8C8D;
        font-size: 10px;
        display: flex;
        align-items: center;
        i{
          margin-left: 5px;
        }
      }
      .ftp-password {
        padding-left: 10px;
        .ftp-password-copy-icon{
          margin-right: 5px;
        }
      }
      input {
        border: none;
        background: none;
      }
      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        margin: 0;
      }
      input[type=number] {
        -moz-appearance: textfield;
      }

      .toggle {
        padding-left: 10px;
      }

    }

  }
}
.create-modal-button-ftp{
  height: 62px;
  padding: 15px;
  text-align: right;
  button{
    height: auto;
    font-size: 14px;
    line-height: 16px;
  }
}