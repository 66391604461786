.acquisition-setup-wrap {
  position: relative;
  height: 100%;
  overflow: hidden;


  .acquisition-nav {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    justify-content: flex-end;
    z-index: 9;

    a {
      display: block;
      padding: 0 15px;
      color: black;
      background: #f2f5f7;
      position: relative;
      left: -1px;
      font-size: 13px;

      &:last-child {
        border-right: 0;

        &:after {
          border-right: 0;
          height: 12px;
          content: '';
          position: absolute;
          right: 0;
          top: 4px;
        }
      }


      &:after {
        border-right: 1px solid #a5a5a5;
        height: 12px;
        content: '';
        position: absolute;
        right: 0;
        top: 5px;
      }

      &.active-class {

        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        left: -2px;

        &:first-child {
          border-top-right-radius: 6px;
          left: 0;
        }

        &:last-child {
          border-top-left-radius: 6px;
        }


        &:after {
          border-right: 0;
          height: 21px;
          content: '';
          position: absolute;
          right: 0;
        }

      }


      &:hover {
        border-radius: 6px 6px 0 0;
        background-color: white;
      }

      &:focus {
        background-color: white;
      }
    }

    .active-class {
      background-color: white;
    }

  }

  .acquisition-body {
    height: 100%;
    overflow: hidden;
    width: 100%;

    .ant-tabs{
      height: 100%;
      display: flex;
      flex-direction: column;
      .tabPaneSpanDisable{
        opacity: 0.5;
      }
      .tabPaneSpanEnable{
        opacity: 1;
      }
      .ant-tabs-top-content{
        height: 100%;
        overflow: hidden;
      }
    }

    .ant-tabs-card {

      .ant-tabs-bar {
        background: #F7F9FA;
        border: 0;
        height: 22px;
        margin: 0;

        .ant-tabs-nav-container {
          height: 22px;
          margin-top: -1px;
          .ant-tabs-nav-wrap{
            background-color: #fff;
          }
          .ant-tabs-nav-scroll {
            float: right;


            .ant-tabs-nav {

              > div {
                text-align: right;
                background: white;
              }

              .ant-tabs-tab {
                border-color: transparent;
                background: #f2f5f8;
                height: 22px;
                line-height: 22px;
                color: #4C4C4C;
                font-weight: 300;
                font-size: 12px;
                margin: 0;
                border-radius: 0 !important;


                &.ant-tabs-tab-active {
                  background-color: #fff;
                  color: #4C4C4C;
                  font-weight: 300;
                  border-radius: 6px 6px 0 0 !important;

                }

              }
            }
          }
        }
      }

      .ant-tabs-content {
        .ant-tabs-tabpane {
          padding: 0;
          &.ant-tabs-tabpane-active {
            height: 100%;
          }
        }
      }
    }

  }
}
