 .form-wrap{
   .form-header{
    height: 30px;
    padding: 15px;
    h1{
     color: #000000;
     font-size: 18px;
    }
    .modal-close{
     i{
      width: 14px;
      height: 14px;
     }
     &:hover{
      path{
       fill: #4c4c4c;
      }
     }
    }
   }
 }