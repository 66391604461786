.data-logger-wrap{
  height: 100%;
  .data-logger-wrap-content{
    height: 100%;
    padding: 25px 0 0 0;
    overflow-y: auto;
  }
  .footer{
    position: relative;
    height: 60px;
    min-height: 60px;

    .footer-save-add-buttons {
      position: absolute;
      right: 0;
      margin-right: 15px;
      button {
        height: auto;
      }

    }
  }

}
