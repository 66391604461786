.footer-wrapper {
  height: 110px;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px 20px;

  .buttons-wrapper {
    flex-direction: row;
    justify-content: space-between;
  }
}
