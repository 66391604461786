.ftp-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .ftp-main-checkbox {
    padding: 0 0 15px 20px;
  }

  .ftp-main-content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .ftp-main-content-order {
      display: grid;
      padding: 20px 20px 32px 20px;
      grid-template-columns: 0fr 1fr 2fr 1fr 1fr 1fr 1.5fr;
      grid-gap: 15px;
      font-size: 14px;

      &:nth-child(even) {
        background-color: #eaf4fb;
      }

      .no-data-label {
        color: #7F8C8D;
      font-style: italic;
      }

      .ftp-checkbox {
        padding-top: 20px;
      }

      .ftp-probe-name {
        color: black;
      }

      .ftp-path {
        overflow-x: scroll;
        width: 100%;

        p {
          width: 100%;
          overflow-x: scroll;

          &::-webkit-scrollbar {
            display: none;
          }
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .ftp-unit {
        justify-self: center;
      }

      .ftp-custom-probe-name {
        justify-self: center;
      }

      label {
        color: #7F8C8D;
        font-size: 10px;
      }
    }
  }

  %flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ftp-footer {
    height: 60px;
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 60px;

    .footer-download-upload-buttons {
      display: flex;
      width: 250px;
    }

    .footer-pagination {
      @extend %flexCenter;
    }

    .footer-save-add-buttons {
      width: 250px;
      display: flex;
      justify-content: flex-end;
    }

    Button {
      font-size: 14px;
      line-height: 19px;
      border: 2px solid #16A085;
      box-sizing: border-box;
      border-radius: 6px;
      color: #16A085;
      margin-left: 15px;

      &:last-child {
        margin-right: 15px;
      }
    }
    .add-button {
      background-color: #16A085;
      color: white;
      font-size: 15px;
    }
  }
}
