.widget-layout {
  padding: 10px;

  [class*='layout-col-'] {
    background-color: rgba(0, 0, 0, .03);
    border: 1px dashed rgba(0, 0, 0, .1);
    height: 100%;
    min-height: 60px;

    &.has-content {
      border: 1px solid #16a085;

      .widget-card-container {
        padding: 3px;
        width: 100%;
        height: 100%;
        box-shadow: 0 1px 5px rgba(0, 0, 0, .2), 0 1px 4px rgba(0, 0, 0, 0.1);
        background-color: #fff;
      }
    }

    .widget-card-container {
      height: 100%;
      .widget-card-wrap {
        width: 100%;
        height: 100%;

        .chart-icon{
          svg{
            path{
              stroke: #7a7a7a;
            }
          }
        }

        h4{
          font-size: 11px;
          font-family: helvetica, arial, sans-serif;
        }
      }
    }
  }
}
.active-drag-card{

  .widget-card-container {
    padding: 3px;
    width: 100%;
    height: 100%;
    box-shadow: 0 1px 5px rgba(0, 0, 0, .2), 0 1px 4px rgba(0, 0, 0, 0.1);
    background-color: rgba(0,0,0,0.05);
    border: 1px solid #d2d2d2;
    h4{
      font-size: 11px;
      font-family: helvetica, arial, sans-serif;
    }
  }

  &.has-content {
    border: 1px solid #16a085;

    .widget-card-container {
      padding: 3px;
      width: 100%;
      height: 100%;
      box-shadow: 0 1px 5px rgba(0, 0, 0, .2), 0 1px 4px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      h4{
        font-size: 11px;
        font-family: helvetica, arial, sans-serif;
      }
    }
    .chart-icon{
      svg{
        path{
          stroke: #7a7a7a;
        }
      }
    }
  }
}
.arrange-button {
  //padding: 5px !important;
  &:hover {
    //background-color: #16a085 !important;
    color: #ff0000 !important;
    border-color: #ffffff !important;
  }
}
