.item-gs {
  height: 70px;
  border-radius: 6px;
  color: #fff;
  transition: opacity .3ms linear;
  cursor: pointer;

  &.tree-structure {
    background-color: #3498DB;
    position: relative;

    &.selected {
      background-color: #004B7C;
    }

    &:hover {
      opacity: .9;
      .item-action{
        opacity: 1;
      }
    }

  }

  &.item-new-tree {
    background-color: #16A085;


  &:hover {
    opacity: .9;
  }

    .item-wrap {

      .item-title {
        font-size: 12px;
        display: flex;
        i {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }

  }

  &.item-start-create-tree {
    background-color: #dddd;

    i {
      color: #E67E22;
      font-size: 40px;
    }

  }

  .item-wrap {
    height: 100%;
    position: relative;
    text-align: center;
    z-index: 2;


    .item-title {
      font-size: 15px;
      overflow: hidden;
      display: -webkit-box;
      -moz-box-orient: vertical;
      /* Mozilla */
      /* autoprefixer: off /
      -webkit-box-orient: vertical;
      / autoprefixer: on */
      -webkit-line-clamp: 3;
      -moz-line-clamp: 3;
      line-height: 18px;
    }

  }
  .item-action{
    position: absolute;
    opacity: 0;
    width: 100%;
    padding: 5px 5px 0 0;
    &:hover{
      opacity: 1;
    }
    .probe-delete-item{
      position: relative;
      display: flex;
      justify-content: flex-end;
      i {
        z-index: 3;
      }
    }
  }

}

