$green_color: rgb(65, 159, 68);
$black_color: rgba(0, 0, 0, 0.65);
.header-syn {
  width: 100%;
  position: relative;

  .top-bar-black-screen-modal {
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 1;
    visibility: visible;


    -webkit-transition: all .25s;
    -o-transition: all .25s;
    transition: all .25s;

    &.js-dashboard-info-modal-hide {
      opacity: 0;
      visibility: hidden;
      .dashboard-view-info-description{
        opacity: 0;
        visibility: hidden;
      }
    }

  }

  .header-container {
    height: 50px;

    .header-syn-logo {
      width: 300px;
      flex-grow: 1;

      img {
        height: 40px;
      }
    }

    .header-syn-menu {
      flex-grow: 2;
      min-width: 300px;

      .active-top-bar {
        color: #272727;
        height: 100%;
        align-items: center;
        display: flex;
        position: relative;

        &:after{
          content: "";
          position: absolute;
          height: 2px;
          width: 100%;
          background-color: #000000;
          bottom: 0;
          left: 0;
        }
      }

      ul {
        padding: 0 15px;
        margin: 0;
        height: 100%;
        color: #555;

        li {
          display: flex;
          align-items: center;
          margin-right: 8px;
          margin-left: 8px;
          font-size: 16px;
          height: 50px;
          font-weight: 500;
          position: relative;

          &.link-with-dropdown {

            .open-submenu {
              background-color: #fff;
              top: 48px !important;
              overflow: auto;
              max-height: 80vh;
              border: 1px solid black;

              ul {
                padding: 0;
                overflow: hidden;
                border: 1px solid;
                border-radius: 0;

                li {
                  padding: 0;
                  margin: 0;
                  height: 30px;
                  border-bottom: 1px solid #272727;
                  font-size: 14px;
                  color: #4c4c4c;
                  background-color: transparent;

                  button{
                    background: transparent;
                    border: 0;
                    width: 100%;
                    height: 100%;
                    text-align: left;
                    cursor: pointer;

                  }

                  &:hover{
                    button{
                      background-color: #e6f7ff;
                    }
                  }
                  &:last-child {
                    border: 0;
                  }

                }
              }

            }

            p {
              margin-right: 22px;

              &:before {
                background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE1IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMy4wNDAxIDAuOTU5MDdMMTMuMDQgMC45NTkyNDRMNy41MDYxMSA2LjU0MDQzTDEuOTYxMjUgMC45NTkwN0MxLjY4NDMxIDAuNjgwMzEgMS4yMzM5NiAwLjY4MDMxIDAuOTU3MDI1IDAuOTU5MDdDMC42ODA5OTIgMS4yMzY5MiAwLjY4MDk5MiAxLjY4NzM5IDAuOTU3MDI1IDEuOTY1MjVMNi45OTI5OCA4LjA0MDkzQzcuMTMwMTggOC4xNzkwNCA3LjMwNjMgOC4yNSA3LjQ5NTA5IDguMjVDNy42Njk0MiA4LjI1IDcuODU3NzggOC4xODEyNyA3Ljk5NzIgOC4wNDA5M0wxNC4wMzA4IDEuOTY3NjVDMTQuMzIxMiAxLjY4OTQgMTQuMzIwNCAxLjIzNjkzIDE0LjA0NDMgMC45NTkwN0MxMy43Njc0IDAuNjgwMzEgMTMuMzE3MSAwLjY4MDMxIDEzLjA0MDEgMC45NTkwN1oiIGZpbGw9IiMyNzI3MjciIHN0cm9rZT0iIzI3MjcyNyIgc3Ryb2tlLXdpZHRoPSIwLjUiLz4KPC9zdmc+");
                content: '';
                position: absolute;
                right: 0;
                width: 13px;
                height: 8px;
                background-size: 13px;
                top: 21px;
                transition: all 0.2s ease;
              }
            }


            &:hover {
              p {
                margin-right: 22px;

                &:before {
                  transform: rotate(180deg);
                }
              }
            }

            .ant-dropdown-open {
              background: #ffffff;

              p {
                margin-right: 22px;

                &:before {
                  transform: rotate(180deg);
                }
              }
            }
          }

          a {
            color: $black_color;

            &:focus {
              text-decoration: none;
              color: #272727;
            }
          }
        }
      }
    }

    .header-settings {
      width: 300px;
      flex-grow: 1;
      height: 100%;

      .header-settings-wrap {
        height: 100%;
        padding-right: 15px;

        .header-language-select{
          .languages-separator{
            width: 20px;
            background-color: rgba(169, 184, 185, 0.47);
            height: 2px;
          }
          button{
            height: 14px;
            padding: 0;
            margin: 0;
            line-height: 0;
            font-size: 11px;
            color: #7F8C8D;

            &.selected-lang{
              font-weight: 600;
              color: #16a085;
            }

          }
        }

        .header-link-global-settings {
          padding-right: 15px;

          .active-global-settings {
            color: $green_color;
          }

          a {
            color: $black_color;
            font-size: 14px;
            display: flex;
            align-items: center;

            i {
              margin-right: 5px
            }
          }
        }

        .header-user-information {

          i {
            color: #7a7a7a;
            font-size: 18px !important;
            margin-right: 5px;
          }

          .user-name {
            font-size: 14px;
            color: #7a7a7a;
          }
        }
      }
    }
  }


}


/* .header-syn ul li.active {
  color: rgb(65, 159, 68);
  position: relative;
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: -1px;
  background-color: rgb(65, 159, 68);
  z-index: 9;
} */
/* .header-syn ul li.active:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: -1px;
  background-color: rgb(65, 159, 68);
  z-index: 9;
} */


.logout-text {
  width: 120px;
}
