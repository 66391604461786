.energy-sidebar {
  display: flex;
  flex-direction: row;
  height: 100%;
  position: absolute;
  right: 0;
  transition: margin 0.25s ease-in-out 0s;
  background-color: rgb(255, 255, 255);
  -webkit-flex-flow: row wrap;

  .right-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-left: 1px solid #eee;
  }

  .content {
    flex-direction: column;
    width: 100%;
    height: 45%;
  }

}

.energy-left-container {
  padding: 10px 10px;
  background-color: white;
  height: 100%;
  margin: 20px;
  border: 1px solid rgb(186, 185, 187);
  border-radius: 5px;

  .header-left-part{
    display: flex;
    flex-direction: row;
    padding: 20px;
  }

  .header-left-part p {
    font-size: 18px;
    padding-left: 20px;
  }

  .common-content {
    display: flex;
    flex-direction: inherit;
    width: 100%;
    padding: 10px 20px;
  }

}