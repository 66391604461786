.recipients-item{
  margin-top: 15px;
  .recipients-action {
    width: 20px;
    position: relative;
    align-items: center;


    .button-icon {
      background: none !important;
      color: #110AFF;
      border: none;
      margin-top: 3px;
      padding: 2px;
      font: inherit;
      cursor: pointer;
      display: flex;
      flex-direction: column;

      &.ant-dropdown-open{
        span{
          z-index: 99;
        }
      }

      span {
        justify-content: center;
        margin: 1px;
        z-index: 50;
        background: #4C4C4C;
        border-radius: 50%;
        height: 5px;
        width: 5px;
        color: #4C4C4C;
      }
      &.ant-dropdown-open{
        span {
            background: #2980B9;
        }
      }
    }
  }

  .ant-dropdown {
    top: 0 !important;
    left: 0 !important;
    width: 150px;
    z-index: 98;



    ul {
      box-shadow: none;
      border: 1px solid #2980B9;
      border-radius: 6px;
      padding-top: 0;
      padding-bottom: 0;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      li {
        border-bottom: 1px solid #2980B9;
        padding: 0;
        display: block;
        height: 30px;
        position: relative;
        overflow: hidden;

        a{
          padding-left: 20px;
          text-align: left;
          display: block;
          top: 0;
        }

        &:last-child {
          border: 0;
          button{
            color: #E74C3C;
          }
        }

        button {
          background-color: transparent;
          width: 100%;
          height: 100%;
          padding-left: 20px;
          text-align: left;
        }

      }
    }

  }
  .recipients-data{
    width: 100%;
    display: grid;
    grid-template-columns: minmax(0, 3fr) minmax(0, 3fr) minmax(0, 3fr);
    grid-gap: 15px;
    padding-top: 2px;

    label{
      font-size: 10px;
      color: #7F8C8D;
    }
    p{
      line-height: 1;
      &.recipients-user{
        color: #000;
        font-weight: 500;
      }
    }
  }
}