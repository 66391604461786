.aggregator {
  width: 100%;
  margin-left: 20px;
  padding: 20px;
  flex-direction: column;
  border: 1px solid rgb(209, 208, 208);

    .content {
      margin-top: 10px;
    }

    .input-label {
      font-size: 15px;
      color: rgb(120, 120, 121);
      margin-right: 10px;
      padding-bottom: 12px;
    }

    .input-wrapper {
      padding-top: 12px;
      width: fit-content;
      display: flex;
      flex-direction: column;
    }
}