.filter-right-sidebar-wrap{
  border-radius: 5px;
  background-color: white;
  height: 100%;
  width: 100%;
  position: relative;
  h2{
    font-size: 18px;
    font-weight: 500;
  }
  .filter-container-wrapper {
    overflow-y: auto;
    height: 100%;
    width: 100%;
    display:flex;
    flex-direction:column;
    position: relative;

    .right-sidebar-filters-body {
      width: 100%;
      background-color: #F7F9FA;
    }

    .right-sidebar-title-header {
      margin: 5px 0;
      width: 100%;
      h2 {
        margin: 10px 0;
        font-size: 16px;
      }
    }

    .right-sidebar-title {
      display: block;

      .right-sidebar-title-header {
        margin-top: 0;
      }
    }

    .filter-footer-buttons {
      display: flex;
      bottom: 0;
      width: 100%;
      justify-content: space-between;

      .test-button {
        color: #000000;
      }

      .create-button {
        color: #000000;
      }
    }

    .right-sidebar-filter-container {
      display: block;
      height: 100%;
    }
  }

}
