.alert-setup-template{
  height: 100%;
  .alert-setup-template-header {
    height: 50px;
    .alert-setup-template-search {
      width: 365px;
      .alerting-search-input{
        width: 250px;
        input {
          width: 250px;
          &:focus{
            box-shadow: none;
          }
        }
      }
      button[disabled]{
        border: 1px solid #d9d9d9;
      }
    }
  }
  .alert-setup-template-content {
    height: 100%;
    overflow: hidden;
    padding-top: 5px;

    .template-list{
      height: 100%;
      overflow-y: auto;
      padding-bottom: 5px;
    }
  }
  footer {
    width: 100%;
    height: 50px;

    .footer-wrap {
      position: relative;
      display: grid;
      grid-template-columns: minmax(0, 2fr) minmax(0, 2fr) minmax(0, 2fr);
      grid-gap: 15px;
      width: 100%;

      .alert-setup-action {
        button {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            margin: 0;
            padding: 0;
          }
        }
      }

    }
    .modal-status{
      div {
        &:last-of-type{
          overflow: inherit;
        }
      }
    }
  }
}


.form-template {
  .form-body {
    padding: 15px;

    .form-body-wrap {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;

      .form-input {
        width: 260px;
        margin-bottom: 15px;

        .errMessage {
          color: rgba(231, 76, 60, 0.75);
          font-size: 10px;
        }
        
        .warning-message {
          color: #7F8C8D;
          font-size: 10px;
          line-height: 10px;
          font-weight: 500;
        }

        input[type=checkbox]:checked {
          background-color: #7F8C8D;
        }

        .ant-checkbox-checked {
          .ant-checkbox-inner{
            background-color: #7F8C8D;
            border-color: #768384;
          }
        }

        label {
          color: #7F8C8D;
          font-size: 11px;
        }

        input {
          height: 36px;
          border-radius: 5px;

          &:focus {
            box-shadow: none;
          }
        }
        .rta{
          height: 100px;
          .rta__autocomplete{
            border-radius: 5px !important;
            .rta__list{
              font-size: 13px;
              .rta__entity{
                padding: 2px 5px;
              }
            }
          }
          textarea{
            padding: 5px;
            resize: none;
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            font-size: 13px;
            &:focus {
              border-color: #40a9ff;
              outline: none;
            }
            &::placeholder {
              color: #bfbfbf;
            }
          }
        }


        &.color-picker {
          width: 70px;
          margin-left: 15px;
          margin-bottom: 0;
          height: 55px;

          .popover {
            position: absolute;
            z-index: 2;
            width: 100%;
          }
          .cover{
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0
          }
          .switch {
            margin-top: 7px;
          }
          .color {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            display: block;
          }
        }
      }
      .legend-name{
        font-size: 12px;
        margin-top: 5px;
      }
      .tag-list{
        flex-wrap: wrap;
        .tag-item{
          margin-right: 5px;
          background-color: rgba(127, 141, 140, .2);
          padding: 2px 2px;
          border-radius: 3px;
          font-size: 10px;
          margin-top: 5px;
        }
      }
    }
  }

  .form-action {
    width: 100%;
    height: 40px;

    button {
      width: 100%;
      background-color: #16A085;
      color: #fff;
      height: 40px;
      border: 0;
      font-size: 16px;
      font-weight: 100;
    }
  }
}
