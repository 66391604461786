@import "../../assets/css/scss/main.scss";


body {
  background-color: #f2f5f7;
}

html,
body,
p,
div,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
img,
pre,
form,
fieldset {
  margin: 0;
  padding: 0;
}

.brush .extent {
  stroke: #000;
  fill-opacity: 0.125;
  shape-rendering: crispEdges;
}

.ant-btn {  
  // Comment start : transition unset because it has some side effects in Chrome and Edge browsers
  transition: none!important;
  -webkit-transition: none!important;
  // Comment end  

  border: 0px;

  & > i {
    margin-right: 2px;
  }
}

.brush .background {
  fill: #ddd;
}

#root {
  height: 100%;
  //overflow: hidden;
  -webkit-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  min-width: 1000px;
}

.root-container {
  height: 100%;
  -webkit-flex: 1 auto;
  flex: 1 auto;
  position: relative;
}

.root-container-body {
  height: 100%;
}

.main-syn {
  padding: 15px;
}

.syn-row {
  padding: 15px;
}

.syn-row-top {
  padding: 0 15px 15px;
}

.syn-row-bottom {
  padding: 15px 15px 0;
}

.syn-row-top-bottom {
  padding: 0 15px;
}

.app-container {
  padding: 15px;
  height: 100%;

  .app-component-header {
    height: 110px;
    padding: 15px;
  }

}

//Error
.error-wrap {
  line-height: 1;

  .error {
    color: rgba(255, 17, 27, 0.88);
    font-size: 11px;
  }
}


.error-input {
  input {
    border-color: rgba(255, 17, 27, 0.88);
  }
}


// Flex
.flex {
  display: flex;
  display: -webkit-flex;
}

.flex-justify-content-center {
  justify-content: center;
  -webkit-justify-content: center;
}

.flex-justify-content-space-between {
  justify-content: space-between;
  -webkit-justify-content: space-between;
}

.flex-justify-content-space-around {
  justify-content: space-around;
  -webkit-justify-content: space-around;
}

.flex-justify-content-end {
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
}

.flex-align-items-center {
  align-items: center;
  -webkit-align-items: center;
}

.flex-align-items-baseline {
  align-items: flex-start;
}

.flex-direction-column {
  flex-direction: column;
  -webkit-flex-direction: column;
}

.flex-direction-row {
  flex-direction: row;
  -webkit-flex-direction: row;
}

.flex-grow-one {
  flex-grow: 1
}

.flex-grow-two {
  flex-grow: 2
}

.flex-grow-tree {
  flex-grow: 3
}

.flex-grow-four {
  flex-grow: 4
}


.grid {
  display: grid;
}

ul {
  list-style: none;
}

// Tooltip
.synapsees-tooltip {
  padding-top: 0;

  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    min-height: auto;
    font-size: 10px;
    border-radius: 2px;
    padding: 3px 6px;
  }
}

// Padding
.row {
  padding: 15px;
}

// Float
.right {
  float: right;
}

// Border
.border-bottom {
  border-bottom: 1px solid #eee !important;
}

.border-top {
  border-top: 1px solid #eee;
}

.syna-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
  -webkit-transition: background-color 2s ease-out;
  -moz-transition: background-color 2s ease-out;
  -o-transition: background-color 2s ease-out;
  transition: background-color 2s ease-out;
}

.root-container-body {
  position: relative;
}

.root-container-body {
  overflow-x: hidden;
  overflow-y: auto;
}

.notification-step {
  font-size: 11px;
  color: #bbb;
  margin-left: 15px;
}

.main-content-wrap {
  height: 100%;
  overflow: hidden;
}

// Background Color
.bg-white {
  background-color: $white;
}

.bg-green {
  background-color: $green;
}

.bg-blue {
  background-color: $blue;
}

.bg-orange {
  background-color: $orange;
}

.bg-gray {
  background-color: $gray;
}

// Border Color
.border-grey {
  border-color: $border-color-grey !important;
}


// Button
.btn {
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  outline: 0;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  font-weight: 400;
  color: #212529;
  text-align: center;

  &:nth-child(even) {
    margin-left: 15px;
  }

  &.btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }

  &.btn-dropdown {
    width: 100%;

  }

  &.btn-blue {
    background-color: $blue;
    color: #fff;
  }

  &.btn-green {
    background-color: #16a085;
    color: #fff;
  }

  &.btn-border-green {
    border: 2px solid #16a085;
    color: #16a085;

    &:hover {
      background-color: #16a085;
      color: #fff;
    }

  }

  &.btn-border-black {
    border: 2px solid #000;
    color: #000;

    &:hover {
      border-color: rgba(0, 0, 0, .7);
      color: rgba(0, 0, 0, .7);
    }

  }

  &.btn-simulate {
    display: inline-block;
    height: 38px;
    top: 1px;
    position: relative;
  }

  &.btn-global-settings {
    height: 40px;
    background: #FFFFFF;
    opacity: 0.6;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    &.active {
      background: transparent;
      box-shadow: none;

      &:hover {
        background: transparent;
      }
    }

    &:hover {
      background: rgba(0, 0, 0, .01);
    }
  }

  &:hover {
    opacity: .9;
  }

}

.btn-no-border {
  color: $blue;
  margin: 0 auto;
  border: 0;
  width: auto;
  display: block;
  background: none;

  &:active {
    outline: 0;
    box-shadow: none;
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }

}

.btn-filter {
  padding: .175rem .75rem;
}

// Color
.matterhorn {
  color: $matterhorn;
}

.black {
  color: $black;
}

.text-red {
  color: $red;
}


// Form
.syn-input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all .3s;
  transition: all .3s;
  outline: 0;

  &.syn-input-lg {
    height: 40px;
    padding: 6px 11px;
    font-size: 16px;
  }
}

button {
  box-shadow: none !important;

  &:focus {
    outline: 0;
  }
}

.ant-calendar-picker-container {
  z-index: 105000;
}

//left side side on route views/details

.dashboard-or-details-left-sidebar-wrap {
  border-radius: 5px;
  background-color: white;
  left: -450px;

  width: 260px;
  margin-right: 5px;
  position: relative;
  transition: left 200ms ease-in .1s;

  .left-sidebar-details-div {
    position: relative;
    height: 100%;
    overflow: auto;

  }

  .left-sidebar-details-eye-div {
    height: 5%;
    padding-left: 15px;

    span {
      margin-left: 5px;
      color: #000000;
      font-weight: 400;
      font-size: 11px;
    }
  }
}
