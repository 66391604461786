.main-viewer {
  height: 100%;
  overflow: hidden;
  padding: 15px;
  margin-left: 0;
  position: relative;

  .main-viewer-wrap {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 0;
    margin-left: 0;
    transition: margin-left 500ms ease-out .1s;

    .dashboard-container {
      width: auto;
      height: 100%;

      .dashboard-list-wrap {
        padding: 15px;
        overflow: auto;
        width: 100%;
        height: 100%;

        .dashboard-list-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(215px, 215px));
          grid-template-rows: auto;
          grid-gap: 20px;
          box-decoration-break: clone;

          .dashboard-drag-handle{
            svg{
              color: #fff;
              cursor: grab;
            }
          }
        }
      }
    }
  }
}