.viewer-header {
  grid-template-columns: 1fr 2fr 3fr 3fr;
  grid-template-rows: auto;
  grid-gap: 5px;
  box-decoration-break: clone;
  align-items: center;
  width: 100%;

  .dashboard-show-probes-title {
    font-size: 11px;
    color: #000;
  }
  .dashboard-view-header-sidebar-button{
    button{
      background: transparent;
      &[ant-click-animating-without-extra-node=true]:after {
        border: 0;
      }
      &:active{
        color: #272727;
      }
      &:focus{
        color: #272727;
      }
      &:hover{
        color: #272727;
      }
    }
  }
  .dashboard-view-info {
    z-index: 999;
    position: relative;
    width: 100%;

    #dashboard-info {
      padding: 0;
      background-color: transparent;
      height: auto;
      z-index: 999;

      &[ant-click-animating-without-extra-node=true]:after {
        border: 0;
      }

    }

    .viewer-name-dashboard {
      font-size: 20px;
      font-weight: 500;
      color: #000;
      margin-left: 10px;
      z-index: 999999;
      margin-right: 40px;
      max-width: 250px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

    }

    .viewer-info-description {
      opacity: 0;
      visibility: hidden;
      background: #fff;
      position: absolute;
      z-index: 98;
      top: -10px;
      padding: 15px;
      left: -15px;
      border-radius: 6px;
      width: 100%;
      min-width: 250px;

      &.js-description-viewer-show{
        opacity: 1;
        visibility: visible;
      }

      .viewer-info-description-content {
        padding-top: 30px;
        padding-bottom: 10px;
        font-size: 11px;
        color: #272727;
      }

      .viewer-info-description-close {
        position: absolute;
        right: 15px;
        top: 13px;
        height: 20px;
        padding: 0;
        line-height: 0;
        box-shadow: none;


        i {
          font-size: 11px;
          color: #9f9f9f;
          font-weight: 600;
        }

      }

    }
  }

  .viewer-date-picker {
    margin-bottom: 15px;

    .dashboard-view-slider {

      button {
        background-color: transparent;
        padding: 5px;

        &[ant-click-animating-without-extra-node=true]:after {
          border: 0;
        }
      }

      .anticon-calendar {
        display: none;
      }

      .anticon-close-circle {
        display: none;
      }

      input {
        padding: 0;
        font-size: 11px;
        border: 0;
        background: transparent;
        height: auto;
        margin-right: 5px;
        text-align: center;
        color: #000;

        &[disabled] {
          color: #888;
        }

      }

      .datepicker-slider-end {
        min-width: 140px!important;
        input {
          margin-left: 5px;
        }
      }
      .datepicker-slider-start {
        text-align: right;
        min-width: 140px!important;

      }

      .ant-slider {
        width: 100%;

        .ant-slider-rail {
          background-color: #DFDFDF;
          border: 2px solid #DFDFDF;
        }

        .ant-slider-track {
          background: linear-gradient(90deg, #159957 0%, #155799 98.62%);
        }

        .ant-slider-handle {
          background-color: #007521;
          border: none;

          &:focus {
            box-shadow: none;
          }

        }

        &.ant-slider-disabled {
          .ant-slider-track {
            background: #888;
            -webkit-transition: all 1ms;
            -o-transition: all 1ms;
            transition: all 1ms;
          }

          .ant-slider-handle {
            background-color: #888;
            -webkit-transition: all 5ms;
            -o-transition: all 5ms;
            transition: all 5ms;
          }
        }
      }
    }

    .viewer-predefine-date {
      ul {
        li {
          margin-right: 5px;

          button {
            background-color: #fff;
            border: 1px solid #cacccc;
            font-size: 10px;
            padding: 1px 3px;
            border-radius: 3px;
            font-weight: 400;
            letter-spacing: .3px;
            color: #333;
            height: auto;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }


  .dashboard-view-cta{
    justify-content: flex-end;
    position: relative;

    button {
      background-color: transparent;
      padding: 0 5px;
    }

    .dashboard-view-cta-save{
      i{
        margin-top: 3px;
      }
    }

    .viewer-cta-select-dashboard{

      &.js-modal--select-dashboards{

        button{
          background-color: #fff;
          border-radius: 5px 5px 0 0;
        }

        .modal-for-select-dashboard {
          visibility: visible;
          opacity: 1;
          right: 26px;
          z-index: 999;


          &.js-open-modal-for-select-dashboard {
            visibility: visible;
            opacity: 1;
          }

          .dashboard-view-template {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: auto;
            grid-gap: 12px;
            -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
            margin-top: 30px;
          }

        }
      }

      .modal-for-select-dashboard {
        position: absolute;
        background-color: #fff;
        right: 15px;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: visibility 0s, opacity 0.3s linear;
        -o-transition: visibility 0s, opacity 0.3s linear;
        transition: visibility 0s, opacity 0.3s linear;
        border-radius: 6px 0 6px 6px;
        width: 220px;
        max-height: 72vh;
        overflow-y: scroll;

        ul{
          li{
            height: 30px;
            border-bottom: 1px solid;
            display: flex;
            align-items: center;

            label{
              display: flex;
              align-items: center;

              > :not(.ant-checkbox) {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 160px;
                height: auto;
              }
            }

            button{
              color: #273C75;
              background: transparent;
              border: 0;
              width: 100%;
              height: 100%;
              padding: 0 10px;
              font-size: 14px;
              text-align: left;
              font-weight: 500;
            }

            &:last-child{border: 0}
            &:hover{
              button{
                color: #fff;
                background: rgba(39, 60, 117, 0.75);;
              }
            }
            &.add-new-dashboard{
              display: flex;
              justify-content: center;
              a{
                color: #16A085;
              }
            }
          }
        }

        h5 {
          color:#272727;
          font-size: 16px;
          font-weight: 600;
        }

        p{
          color:#272727;
          font-size: 11px;
        }

      }

    }

  }
}

.dashboard-list-checkbox {
  margin-left: 10% !important;
}

.viewer-header-info-modal {
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 1;
  visibility: visible;

  &.js-viewer-header-info-modal-hide {
    opacity: 0;
    visibility: hidden;
  }

}
