.alerting-setup-content {
  padding: 0 15px;
  height: 100%;
  overflow: hidden;
  position: relative;

  .alerting-setup-wrap {
    width: 100%;
    background: #fff;
    height: 100%;
    border-radius: 5px;
    .tab-header{
      height: 24px;
    }
  }

  .tab-navigation {
    float: right;
    background-color: #f2f5f7;

    span{
      button{
        margin: 0;
        padding: 3px 15px;
        border-radius: 0;
        border: 0;
        background-color: #f2f5f7;
        cursor: pointer;
        font-size: 12px;
      }
      &.active{
        button{
          background-color: #ffffff;
        }
      }
      &:last-of-type{
        button{
          border-radius: 0 5px 0 0;
        }
      }
    }
  }
  .tab-content{
    height: 100%;
    overflow: hidden;
    position: relative;
    padding: 15px;
    main{
      height: 100%;
      position: relative;
    }
  }
}